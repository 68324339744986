@media (max-width: 768px) {
    .mat-mdc-table[matTableResponsive] {
      .mat-mdc-header-row {
        //display: flex;
        //flex-wrap: wrap;
        //height: auto;
        //position: sticky;
        //top: 0;
        //z-index: 2;
        display: none;

        th.mat-mdc-header-cell {
          display: flex;
          flex: 1;
          align-items: center;
          padding: 1rem 0.5rem;
          justify-content: space-around;

          & > .mat-sort-header-container {
            padding-left: 15px; // padding is used to center the header table cells, due to the width of the sort icon
          }

          &:not([mat-sort-header]),
          &.mat-sort-header-disabled,
          &.mat-column-actions {
            display: none;
          }
        }
      }

      // .detail-row is the expanded content for expandable row
      .mat-mdc-row:not(.detail-row) {
        height: auto;

        td.mat-mdc-cell:not(.mat-column-actions) {
          display: grid;
          grid-template-columns: 50% 50%;
          padding: 0.5rem;
          text-align: right;
          word-break: break-word;

          & > * {
            grid-column: 2;
          }

          &:before {
            content: attr(data-column-name);
            text-align: left;
          }
        }

        td.mat-column-actions {
          display: flex;
          justify-content: center;
          padding: 0 0.5rem;

          & > button {
            margin: 0.5rem 0;
          }
        }

        &:last-of-type td:last-of-type {
          border: none;
        }

        &:first-of-type {
          border-top: 1rem solid #fafafa;
        }

        &:not(:last-of-type) td:last-of-type {
          border-bottom: 2rem solid #fafafa;
        }
      }

      .mdc-data-table__row:last-child .mat-mdc-cell {
        border-bottom-color: var(--mat-table-row-item-outline-color, rgba(0, 0, 0, 0.12));
        border-bottom-width: var(--mat-table-row-item-outline-width, 1px);
        border-bottom-style: solid;
      }

      // Expanded content
      .mat-mdc-row.detail-row {
        display: none;
      }
      .toggle-button {
        display: none;
      }
    }

    .mat-mdc-card,
    .mat-expansion-panel {
      .mat-mdc-table[matTableResponsive] {
        border: 0.5rem solid #fafafa;
      }
    }
}
