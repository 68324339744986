@import "src/styles/mixin";

.fc {
  --fc-button-bg-color: var(--primary-700);
  --fc-button-border-color: var(--primary-700);

  --fc-button-active-bg-color: var(--primary-800);
  --fc-button-active-border-color: var(--primary-800);

  --fc-button-hover-bg-color: var(--primary-800);
  --fc-button-hover-border-color: var(--primary-800);

  --fc-button-text-color: var(--font-white);
}

.fc-button {
  min-height: 34px !important;
}
