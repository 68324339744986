@import "src/styles/mixin";

mat-dialog-container.mat-mdc-dialog-container {
  --mdc-dialog-subhead-size: 18px;
  --mdc-dialog-subhead-line-height: 20px;
  --mdc-dialog-supporting-text-font: "Mulish";
  --mdc-dialog-supporting-text-size: 14px;
  --mdc-dialog-supporting-text-line-height: 22px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0.035em;
  --mdc-dialog-supporting-text-color: var(--font-2);

  --mdc-dialog-container-shape: 20px;

  .close-button {
    --mdc-icon-button-icon-color: var(--accent-danger-1);
  }

  .mat-mdc-dialog-title {
    @include h7;
    // padding: 0 20px 20px;
  }

  .title-row {
    padding: 10px 20px 10px 20px;

    .mat-dialog-close {
      @include forms-button-large;
      color: var(--accent-danger-1);

      .mat-icon {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 840px) {
  .cdk-global-overlay-wrapper {
    display: block;

    .cdk-overlay-pane {

      height: 100%;
      width: 100% !important;
      max-width: 100vw !important;

      //margin-top: env(safe-area-inset-top) !important;
      //margin-top: constant(safe-area-inset-top) !important;

      margin-top: 200px;

      .mat-mdc-dialog-container {
        --mdc-dialog-container-shape: 0;

        .title-row {
          padding: 14px 20px 14px 20px;
        }

        .title-row {
          //make dialog title row sticky
          position: sticky;
          top: 0;
          z-index: 1;
          background-color: white;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
          margin-bottom: 25px;
        }

        .mat-mdc-dialog-content {
          &.mat-dialog-content-no-padding {
            padding: 0;
          }

          max-height: max-content;
          padding: 0 20px 20px 20px;
        }
      }
    }
  }

  .mat-mdc-dialog-container {
    overflow-x: hidden !important;
  }
}
