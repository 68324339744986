@import "../../styles/mixin";
@import "../../styles";

html, body {
  @include font-regular;
}
h1, h2, h3, h4, h5, h6 {
  @include header;
  font-weight: 500;
}

#app-wrapper.not-tenant.main-container {
  padding-bottom: 0;
  height: 100%;
  &.active {
    transform-style: unset;
  }
  .ng-toolbar-container {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 2;
  }
  .mat-toolbar-row {
    display: flex;
    align-items: center;
  }
  .mat-toolbar-row, mat-toolbar-row.mat-toolbar-single-row {
    height: 70px;
    background-color: #FFFFFF;
    padding: 0;
    justify-content: space-between;
  }
  .main-toolbar-mobile {
    min-height: 57px;
    .mat-toolbar-row {
      height: 57px;
    }
  }
  .mat-drawer-backdrop {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    display: block;
    z-index: 3;
    visibility: hidden;
    &.mat-drawer-shown {
      visibility: visible;
    }
  }
  .mat-drawer-container {
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow: hidden;
    height: 100%; // todo: not sure maybe delete
  }
  .mat-drawer-inner-container {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  .sidebar-closed {
    .mat-drawer.mat-drawer-side {
      width: 68px;
    }
    .mat-drawer-content {
      margin-left: 68px;
    }
  }
  .mat-drawer-content {
    height: 100%;
    padding-top: 70px;

    margin-left: 200px;

    transition-duration: 400ms;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-property: transform,margin-left,margin-right;

    position: relative;
    z-index: 1;
    display: block;
    height: 100%;
    overflow: auto;

    &.mobile {
      margin-left: 0;
    }
  }
  .mat-drawer-content.mobile {
    padding-bottom: 100px; //so not cut off by bottom nav
  }
  .mat-sidenav {
    position: fixed;
    z-index: 4;
    //width: 200px;
    border-right: 1px solid #eff1f3;
    transition: width 0.3s ease-in-out;
    top: 70px;
    bottom: 0;
    transform: none;
    visibility: visible;
    &.mobile {
      top: 57px;
    }

    .mat-mdc-list-base {
      .mat-mdc-list-item {
        @include font-color-3;
        @include body3;
        padding: 0 18px 0 25px;
        width: 100%;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.01em;

        .expand-control{
          display: none;
        }
        &:hover {
          @include background-highlight;
        }
        &.mat-mdc-list-item-with-avatar {
          display: block;
          height: 56px;
        }
        .mdc-list-item__content {
          display: flex;
          flex-direction: row;
          align-items: center;
          box-sizing: border-box;
          position: relative;
          height: inherit;
        }
      }
    }
    .mat-mdc-list-item-icon {
      width: 16px;
      height: 16px;
    }
  }
  .mat-drawer.mat-drawer-side {
    width: 200px;
    border-right: 1px solid #eff1f3;
    transition: width 0.3s ease-in-out;
    background-color: white;
  }
  a:hover svg circle, a:hover svg{
    fill: transparent;
  }
  mat-icon.mat-icon {
    fill: none;
  }
  .mat-mdc-button-base {
    border: none;
  }
  .mat-mdc-button, .mat-mdc-icon-button, .mat-mdc-outlined-button, .mat-mdc-unelevated-button {
    box-sizing: border-box;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: none;
    border: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: 64px;
    line-height: 36px;
    padding: 0 16px;
    border-radius: 4px;
    overflow: visible;
  }
  .mat-mdc-icon-button {
    padding: 0;
    min-width: 0;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    line-height: 40px;
    border-radius: 50%;
    background-color: transparent;
  }
  mat-icon {
    width: 24px;
    height: 24px;
    svg {
      width: inherit;
      height: inherit;
    }
  }
  .mobile-bottom-navigation {
    //&.mobile-bottom-navigation__hide {
    //  height: 0;
    //}
    /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
    .mat-mdc-list-base .mat-list-item-content {
      align-items: center;
    }
    .mat-mdc-list-base .mat-mdc-list-item .mat-mdc-list-item-icon, .mat-list-base .mat-list-option .mat-list-item-icon {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      font-size: 24px;
      box-sizing: content-box;
      border-radius: 50%;
      padding: 4px;
    }
  }


  // NEW NAVBAR ------------------------
  .mat-toolbar-row {
    //display: flex;
    //flex-direction: row;
    //justify-content: space-between;
  }
  .logo-container {
    position: relative;
    display: flex;
    align-items: center;

    width: 200px;
    height: 100%;
    padding: 0 20px;

    border-right: 1px solid #eff1f3;

    transition: width 0.3s ease-in-out;
    img {
      object-fit: contain;
      max-width: 160px;
      transition: max-width 0.3s ease-in-out;
    }
    &.logo-container-closed {
      width: 68px;
      padding: 0 5px;
      img {
        max-width: 58px;
      }
    }
    .sidebar-control {
      border: none;
      border-radius: 50%;
      position: absolute;
      right: -13px;
      top: calc(50% - 13px);
      width: 26px;
      height: 26px;
      line-height: 18px;
      font-size: 18px;
      background: #FFFFFF;
      box-shadow: 0 7px 20px -2px rgba(21, 21, 56, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      .mat-icon {
        width: 18px;
        height: 18px;
        fill: none;
      }
      &.sidebar-control-opened {
        .mat-icon {
          transform: scaleX(-1);
        }
      }
      &.sidebar-control-closed {

      }
    }
  }
  .burger-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 15px;
    .mat-mdc-icon-button {
      margin-right: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      .mat-icon {
        width: 32px;
        height: 32px;
        line-height: 32px;
      }
    }
  }
  .title-container {
    flex: 1;
    padding: 0 30px;
  }
  .profile-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 15px;
    padding: 0 25px;
    .mat-icon {
      width: 20px;
      height: 20px;
    }
    .profile-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 40px;
      border: 1px solid gray;
      column-gap: 10px;
      border-radius: 20px;
      padding: 10px;
      cursor: pointer;

      span {
        @include body4-medium;
        @include font-color-1;
      }
    }
    .profile-button-mobile {
      cursor: pointer;
      width: 28px;
      height: 28px;
      border-radius: 14px;
      overflow: hidden;
      display: flex;
      img {
        width: 28px;
        height: 28px;
      }
    }

  }

  .popup-container-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    .sidenav-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      row-gap: 5px;
      .mat-icon {
        cursor: pointer;
      }
      p {
        margin-bottom: 0;
      }
      .avatar {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        margin-bottom: 9px;
      }
      .mat-divider {
        margin-top: 20px;
        width: 100%;
        display: block;
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: rgba(0, 0, 0, 0.12);
      }
    }
    .mat-mdc-list-base .mat-mdc-list-item .mdc-list-item__content  {
      text-overflow: ellipsis;
      white-space: nowrap;
      align-self: center;
      flex: 1;
    }
    .mat-mdc-menu-panel {
      position: absolute;
      right: 10px;
      top: 57px;
    }
  }
  .mat-mdc-menu-panel{
    position: absolute;
    right: 10px;
    top: 57px;
  }
  .mat-mdc-menu-panel {
    min-width: 112px;
    max-width: 280px;
    -webkit-overflow-scrolling: touch;
    max-height: calc(100vh - 48px);
    outline: 0;
    min-height: 64px;
  }
  .mat-mdc-menu-content:not(:empty) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.form {
  div.table-responsive {
    padding: 0 0 20px 0;
    div {
      padding: 0;
    }
  }
}
.panel-body {
  .table-responsive {
    border-radius: unset;
    margin-top: unset;
  }
}
.table-responsive {
  border-radius: 24px;
  background-color: #ffffff;

  .dataTables_wrapper {
    padding: 0 0 20px 0;
    .dataTables_length {
      position: sticky;
      left: 20px;
      top: 20px;
      margin-bottom: 20px;
      //float: none;
    }
    .pull-right {
      position: sticky;
      right: 20px;
      top: 20px;
      float: none;
    }
    .dataTables_info {
      position: sticky;
      bottom: 20px;
      left: 20px;
      margin-top: 20px;
    }
    .dataTables_paginate {
      position: sticky;
      bottom: 0;
      right: 0;
    }
  }
}
.filtered-table {

}

.table.dataTable {
  background-color: transparent;
  &.dtr-inline.collapsed>tbody>tr>td:first-child:before,
  &.dtr-inline.collapsed>tbody>tr>th:first-child:before {
    top: calc(50% - 7px);
  }
  thead {
    tr {
      th {
        border-bottom: 1px solid #F4F4F4;
        border-right: 0;
        @include h9-bold;
        @include font-color-3;
      }
    }
  }

  tbody {
    border-top: 0;
    @include body4-medium;
    @include font-color-3;
    tr {
      height: 60px;
      &:nth-child(even) {
        background-color: transparent;
      }
      td {
        vertical-align: middle;
        border-bottom: 1px solid #F4F4F4;
      }
      &.selected {
        background-color: transparent;
      }
    }
  }
}

.mdc-list-item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0;
  align-items: stretch;
  cursor: pointer;
}

.mat-mdc-nav-list.side-nav-list .mat-mdc-list-item .mdc-list-item__primary-text {
  display: flex;
  align-items: center;
}

