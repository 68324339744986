@import "src/styles/mixin";
@import "src/styles/colors";

$primary-700: #008d97;
$primary-700-accent: #ffffff;

$danger-800: #d30808;
$danger-400: #ff9c9c;
$danger-50: #fef6f6;

.mat-mdc-card.mdc-card {
  --mdc-elevated-card-container-shape: 12px;
  --mdc-outlined-card-container-shape: 12px;
  border-radius: 12px;

  &.mat-card-danger {
    color: $danger-800;
    border: 1px solid $danger-400;
    background-color: $danger-50;
  }
  &.mat-card-primary {
    color: $primary-700-accent;
    // background-color: var($--primary-700);
    // border: 1px solid var($--primary-700);
    background-color: $primary-700;
    border: 1px solid $primary-700;
  }

  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
