@import "colors";

$primary: #024953;
$primaryBackground: #f6fbfc;
$highlightBackground: #f5f7fa;

@mixin spread-map($map: ()) {
  @each $key, $value in $map {
    #{$key}: $value;
  }
}

@mixin font-regular {
  font-family: "Mulish", sans-serif;
}

@mixin header {
  font-family: "Work Sans", sans-serif;
}

@mixin font-primary {
  color: $primary;
}
@mixin font-color-1 {
  color: #2f353b;
}
@mixin font-color-2 {
  color: #323b4b;
}
@mixin font-color-3 {
  color: #4e5d78;
}
@mixin font-color-4 {
  color: #8a94a6;
}

@mixin background-primary {
  background-color: $primaryBackground;
}

@mixin background-highlight {
  background-color: $highlightBackground;
}

@mixin body1-medium {
  font-family: "Mulish";
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.02em;
}

@mixin body2-medium {
  font-family: "Mulish";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
}
@mixin body2 {
  @include body2-medium;
  font-weight: 400;
}

@mixin body3 {
  font-family: "Mulish";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.035em;
}

@mixin body3-medium {
  @include body3;
  font-weight: 600;
}

@mixin body4 {
  font-family: "Mulish";
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.045em;
}

@mixin body4-medium {
  @include body4;
  font-weight: 600;
}

@mixin body5-medium {
  font-family: "Mulish";
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.05em;
}

@mixin h2 {
  font-size: 48px;
  line-height: 72px;

  font-family: "Work Sans";
  font-weight: 500;
  letter-spacing: 0.015em;
}

@mixin h2-bold {
  @include h2;
  font-weight: 600;
}

@mixin h3 {
  font-size: 36px;
  line-height: 54px;

  font-family: "Work Sans";
  font-weight: 500;
  letter-spacing: 0.015em;
}

@mixin h3-bold {
  @include h3;
  font-weight: 600;
}

@mixin h4 {
  font-size: 32px;
  line-height: 48px;

  font-family: "Work Sans";
  font-weight: 500;
  letter-spacing: 0.015em;
}

@mixin h4-bold {
  @include h4;
  font-weight: 600;
}

@mixin h5 {
  font-size: 24px;
  line-height: 36px;

  font-family: "Work Sans";
  font-weight: 500;
  letter-spacing: normal;
}

@mixin h5-bold {
  @include h5; 
  
  font-weight: 600;
  letter-spacing: 0.025em;
}

@mixin h6 {
  font-size: 20px;
  line-height: 30px;

  font-family: "Work Sans";
  font-weight: 500;
  letter-spacing: 0.015em;
}
@mixin h6-bold {
  @include h6;
  font-weight: 600;
}
@mixin h7 {
  font-size: 18px;
  line-height: 20px;

  font-family: "Work Sans";
  font-weight: 500;
  letter-spacing: 0.015em;
}
@mixin h7-bold {
  @include h7;
  font-weight: 600;
}
@mixin h8 {
  font-size: 16px;
  line-height: 18px;

  font-family: "Work Sans";
  font-weight: 500;
  letter-spacing: 0.3px;
}
@mixin h8-bold {
  @include h8;
  font-weight: 600;
}
@mixin h9 {
  font-size: 14px;
  line-height: 16px;

  font-family: "Work Sans";
  font-weight: 500;
  letter-spacing: 0.015em;
}
@mixin h9-bold {
  @include h9;
  font-weight: 600;
}

@mixin forms-label {
  font-size: 14px;
  line-height: 16px;
  font-family: "Work Sans";
  font-weight: 600;
  letter-spacing: 0.015em;
}

@mixin forms-input {
  font-size: 14px;
  line-height: 18px;
  font-family: "Mulish";
  font-weight: 400;
  letter-spacing: 0.015em;
}

@mixin forms-button-large {
  font-size: 14px;
  line-height: 16px;
  font-family: "Work Sans";
  font-weight: 600;
  letter-spacing: 0.015em;
}

@mixin forms-button-medium {
  font-size: 13px;
  line-height: normal;
  font-family: "Work Sans";
  font-weight: 600;
  letter-spacing: 0.195px;
}

@mixin forms-button-small {
  --mdc-typography-button-font-size: 11px;
  font-size: 11px;
  font-style: normal;
  --mdc-typography-button-font-family: "Work Sans";
  font-family: "Work Sans";
  --mdc-typography-button-font-weight: 600;
  font-weight: 600;
  --mdc-typography-button-letter-spacing: 0.165px;
  letter-spacing: 0.165px;
}

@mixin accent-font-1 {
  color: var($--font-1);
}
@mixin accent-font-2 {
  color: var($--font-2);
}
@mixin accent-font-3 {
  color: var($--font-3);
}
@mixin accent-font-4 {
  color: var($--font-4);
}
@mixin accent-font-5 {
  color: var($--font-5);
}
@mixin accent-font-white {
  color: var($--font-white);
}

@mixin accent-font-success-1 {
  color: var($--accent-success-1);
}
@mixin accent-font-success-2 {
  color: var($--accent-success-2);
}
@mixin accent-font-success-3 {
  color: var($--accent-success-3);
}
@mixin accent-font-success-4 {
  color: var($--accent-success-4);
}

@mixin accent-primary-1 {
  color: var($--primary-1);
}
@mixin accent-primary-2 {
  color: var($--primary-2);
}
@mixin accent-primary-3 {
  color: var($--primary-3);
}
@mixin accent-primary-4 {
  color: var($--primary-4);
}
@mixin accent-primary-5 {
  color: var($--primary-5);
}
@mixin accent-primary-6 {
  color: var($--primary-6);
}
