@use "@angular/material" as mat;
@import "colors";
@import "fonts";

$__primary: #008d97;
$__secondary: #ffce73;

// all existing css variables mapped in :root so can be used like `var($--primary-50)`
$theme-main: (
  $--primary-1: $__primary,
  $--primary-2: darken($__primary, 7%),
  $--primary-3: #006d7b,
  $--primary-4: #1fb4bf,
  $--primary-5: #cbedef,
  $--primary-6: #f6fbfc,

  $--primary-t-7: rgba($__primary, 0.7),
  $--primary-50: lighten($__primary, 80%),
  $--primary-100: lighten($__primary, 60%),
  $--primary-200: lighten($__primary, 50%),
  $--primary-300: lighten($__primary, 40%),
  $--primary-400: lighten($__primary, 30%),
  $--primary-500: lighten($__primary, 20%),
  $--primary-600: lighten($__primary, 10%),
  $--primary-700: var($--primary-1),
  $--primary-800: darken($__primary, 7%),
  $--primary-900: darken($__primary, 14%),
  $--secondary-1: $__secondary,
  $--secondary-2: darken($__secondary, 7%),
  $--secondary-3: #ff9a7b,
  $--secondary-4: #ffe2ab,
  $--secondary-5: #fff3dc,

  $--secondary-50: lighten($__secondary, 80%),
  $--secondary-100: lighten($__secondary, 60%),
  $--secondary-200: lighten($__secondary, 50%),
  $--secondary-300: lighten($__secondary, 40%),
  $--secondary-400: lighten($__secondary, 30%),
  $--secondary-500: lighten($__secondary, 20%),
  $--secondary-600: lighten($__secondary, 10%),
  $--secondary-700: var($--secondary-1),
  $--secondary-800: darken($__secondary, 7%),
  $--secondary-900: darken($__secondary, 14%),
  $--gray-1: #090b0c,
  $--gray-2: #2f353b,
  $--gray-3: #555f6a,
  $--gray-4: #7d8997,
  $--gray-5: #959faa,
  $--gray-6: #adb4bd,
  $--gray-7: #dcdfe3,
  $--gray-8: #f4f4f4,
  $--gray-9: #fafafa,

  $--accent-success-1: #21b17d,
  $--accent-success-2: #00834f,
  $--accent-success-3: #8bd0b0,
  $--accent-success-4: #e2f4ec,
  $--accent-warning-1: #ffb443,
  $--accent-warning-2: #ff9a3d,
  $--accent-warning-3: #f6c378,
  $--accent-warning-4: #fffbf2,
  $--accent-warning-5: #fff9e5,

  $--accent-danger-1: #ff2b2b,
  $--accent-danger-2: #d30808,
  $--accent-danger-3: #ff9c9c,
  $--accent-danger-4: #fef6f6,

  $--accent-info-1: #276ef1,
  $--accent-info-2: #0a3e9e,
  $--accent-info-3: #9abbf8,
  $--accent-info-4: #f5faff,

  $--accent-attention-1: #8f1ae8,
  $--accent-attention-2: #7311bd,
  $--accent-attention-3: #b370f1,
  $--accent-attention-4: #f3e7fc,

  $--font-1: #2f353b,
  $--font-2: #323b4b,
  $--font-3: #4e5d78,
  $--font-4: #8a94a6,
  $--font-5: #b0b7c3,

  $--font-white: #ffffff,

  $--background-1: #c1c7d0,
  $--background-2: #f3f3f3,
  $--background-3: #fafbfc,
  $--background-4: #ffffff,
  $--background-5: #f2f5f7,
);

$primary-palette: (
  50: var($--primary-50),
  100: var($--primary-100),
  200: var($--primary-200),
  300: var($--primary-300),
  400: var($--primary-400),
  500: var($--primary-500),
  600: var($--primary-600),
  700: var($--primary-700),
  800: var($--primary-800),
  900: var($--primary-900),
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: rgba(black, 1),
    700: rgba(white, 1),
    800: rgba(white, 1),
    900: rgba(white, 1),
  ),
);
$secondary-palette: (
  50: var($--secondary-50),
  100: var($--secondary-100),
  200: var($--secondary-200),
  300: var($--secondary-300),
  400: var($--secondary-400),
  500: var($--secondary-500),
  600: var($--secondary-600),
  700: var($--secondary-700),
  800: var($--secondary-800),
  900: var($--secondary-900),
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: rgba(black, 0.87),
    700: rgba(black, 0.87),
    800: rgba(black, 0.87),
    900: rgba(black, 0.87),
  ),
);

$my-primary: mat.m2-define-palette($primary-palette, 700);
$my-accent: mat.m2-define-palette($secondary-palette, 700);

$my-theme: mat.m2-define-light-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent,
  ),
  density: -2
));
@include mat.all-component-themes($my-theme);

@include mat.all-component-typographies($custom-typography-config);
@include mat.sidenav-typography($toolbar-typography-config);
@include mat.toolbar-typography($toolbar-typography-config);
@include mat.table-typography($table-typography-config);

:root {
  @include spread-map($theme-main);
}


// moved from styles.scss, default styling example

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$candy-app-primary: mat.m2-define-palette(mat.$m2-teal-palette);
$candy-app-accent:  mat.m2-define-palette(mat.$m2-teal-palette, 600, 500, 700);

// The warn palette is optional (defaults to red).
$candy-app-warn:    mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$candy-app-theme: mat.m2-define-light-theme((
  color: (
    primary: $candy-app-primary,
    accent: $candy-app-accent,
    warn: $candy-app-warn
  ),
  typography: mat.m2-define-typography-config(),
  density: 0,
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
