@import "src/styles/colors";

mat-checkbox.mat-mdc-checkbox {
  display: flex;

  label {
    margin-bottom: 0; // override bootstrap styling
  }

  .mdc-form-field {
    white-space: normal;
  }

  .mdc-checkbox {
    --mdc-checkbox-selected-checkmark-color: var(--font-white);
    --mdc-checkbox-unselected-icon-color: var(--gray-6);
    // --mdc-checkbox-unselected-pressed-icon-color: #var(--gray-8);
    --mdc-checkbox-unselected-hover-icon-color: var(--gray-5);
    --mdc-checkbox-unselected-focus-icon-color: var(--gray-5);
  }

}

mat-checkbox.mat-mdc-checkbox .mdc-checkbox__background, 
.mat-pseudo-checkbox {
  border-radius: 4px !important; // important for .mat-pseudo-checkbox
}

.mat-pseudo-checkbox {
  color: var(--gray-6) !important; // match --mdc-checkbox-unselected-icon-color
  &::hover {
    color: var(--gray-5) !important; // match --mdc-checkbox-unselected-hover-icon-color
  }
}

