@import "src/styles/mixin";
@import "src/styles/colors";

body .overlay-content.custom-date-selector-overlay mat-calendar {
  width: 262px;

  --mat-datepicker-calendar-header-text-color: var(--primary-1);
  --mat-datepicker-calendar-header-text-weight: 600;
  --mat-datepicker-calendar-header-divider-color: none;

  .mat-mdc-button.mat-unthemed {
    --mdc-text-button-label-text-color: var(--font-1) !important;
  }

  .mat-calendar-table-header {
    @include font-primary;
  }
  .mat-calendar-controls {
    justify-content: space-between;
    margin-top: 0;

    .mat-calendar-previous-button {
      order: 1;
    }
    .mat-calendar-period-button {
      order: 2;
      .mdc-button__label svg {
        display: none;
      }
    }
    .mat-calendar-next-button {
      order: 3;
    }
    .mat-calendar-spacer {
      display: none;
    }
  }

  // selected font color + weight
  .mat-calendar-body-selected {
    font-weight: 700;
    --mat-datepicker-calendar-date-selected-state-text-color: var(--font-white);
  }

  // hover background color + font color + weight
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    font-weight: 700;
    --mat-datepicker-calendar-date-text-color: var(--font-white);
  }

  // range background color + font color + weight
  .mat-calendar-body-in-range {
    font-weight: 700;
    --mat-datepicker-calendar-date-text-color: var(--primary-1);
    --mat-datepicker-calendar-date-in-range-state-background-color: var(--primary-5);
  }
}
