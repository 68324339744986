@import "src/styles/mixin";

.mat-mdc-menu-panel {
  &.pq-menu {
    margin-top: 18px;
    position: relative;
    overflow: unset;
    border-radius: 15px;
    box-shadow: 0px 10px 85px -15px rgba(227, 230, 236, 0.95) !important;
  }
  &.pq-menu-center {
    margin-left: calc(-50% + 36px);
  }
  &.pq-menu-right {
    .triangle {
      left: unset;
      right: 0;
    }
  }
  &.main-navigation-menu {
    .mat-mdc-menu-content {
      padding: 10px;
      border-radius: 15px;
      background-color: #FFFFFF;
      .mat-mdc-menu-item {
        padding: 10px;
        height: unset;
        line-height: unset;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        border-radius: 10px;
        min-width: 250px;
        @include body3;
        @include font-color-2;
        background-color: white;
        &.active,
        &:hover {
          @include font-primary;
          @include background-highlight;
        }
        span {
          display: flex;
          align-items: center;
          flex: 1;
          @include body3;
          @include font-color-2;
        }
        .mdc-list-item__primary-text {
          display: flex;
          align-items: center;
        }
        .mat-icon-container {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          // background-color: var($--primary-6);
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 15px;
        }
        .mat-icon {
          @include font-primary;
          margin-right: 0;
          &.checkmark_circle {
            margin-left: auto;
          }
        }
      }
    }
  }
  &.avatar__menu-container {
    padding: 15px;
    .mat-mdc-menu-content {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      width: 175px;
      overflow: unset;
    }
  }
  &.sidenav-profile-unit-menu {
    //right: -355px;
    right: calc(-100% + 13px);
    margin-left: 0;
    top: 40px;

  }
  .mat-mdc-menu-item-submenu-trigger {
    padding-right: 0;
    /*TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version.*/
    .mat-menu-submenu-icon {
      display: none;
    }
  }
  .mat-mdc-menu-item {
    .mat-ripple-element {
      opacity: 0.1;
      @include background-primary;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version.*/
    &.mat-menu-item-highlighted {
      @include background-primary;
    }
    &.unit-name {
      padding: 0 20px;

      .mat-icon {
        line-height: 48px;
      }
    }
  }
  .triangle{
    position: absolute;
    width: 60px;
    height: 20px;
    overflow: hidden;
    top: -20px;
    left: calc(50% - 30px);
    &:after {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      background: #FFFFFF;
      -ms-transform: rotate(45deg);
      transform: rotate(45deg); /* Prefixes... */

      box-shadow: 0px 26px 85px -15px rgba(227, 230, 236, 0.95);
      top: 50%;
      left: 20px;
    }
  }
}
