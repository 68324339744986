@import "src/styles/mixin";

mat-nav-list.mat-mdc-list-base {
  padding: 0;
}

mat-nav-list.mat-mdc-list-base .mat-mdc-list-item,
mat-nav-list.mat-mdc-list-base .mat-list-option {
  padding: 0 18px 0 25px;
}
mat-list.mat-mdc-list-base .mat-mdc-list-item,
mat-list.mat-mdc-list-base .mat-list-option {
  padding: 0;
}

.mat-mdc-list-item {
  display: flex;
  position: relative;
  justify-content: flex-start;
  overflow: hidden;
  align-items: stretch;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}

.mobile-bottom-navigation {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: #FFFFFF;
  padding: 0;
  height: 78px;
  justify-content: center;
  border-radius: 10px 10px 0 0;
  transition: height 0.3s ease-in-out;
  &.mobile-bottom-navigation__hide {
    height: 0;
  }
  .mat-mdc-nav-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    &.mat-mdc-list-base {
      .mat-mdc-list-item {
        height: 100%;
        padding: 0;
        @include font-color-4;
        align-items: center;
        .mdc-list-item__content {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          padding: 10px;
          .mdc-list-item__primary-text {
            padding: 0;
            text-align: center;
            align-items: center;
            @include body5-medium;
            @include font-color-4;
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }
}

.mat-mdc-nav-list {
  .mat-mdc-list-item {
    @include font-color-3;
    @include font-regular;
    font-size: inherit !important;
    height: 47px;
    &:hover {
      @include background-highlight;
    }
  }
  &.mat-mdc-list-base {
    &.profile-nav {
      .mdc-list-item__content .mdc-list-item__primary-text {
        @include h5-bold;
        @include font-color-3;
        display: flex;
        flex-direction: row;
        align-items: center;
        span {
          padding-left: 16px;
        }
        .mat-icon {
          box-sizing: content-box;
          padding: 4px;
          line-height: 24px;
        }
      }
      .mat-mdc-list-item {
        .mat-icon {
          color: $primary;
        }
        /*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
        &.mat-list-item-with-avatar {
          height: 48px;
        }
      }
    }
  }
  &.side-nav-list {
    .mat-mdc-list-item {
      height: 56px;
      .mdc-list-item__primary-text {
        display: flex;
        align-items: center;
      }
      .title {
        @include body3;
        @include font-color-3;
        padding-left: 16px;
        width: 140px;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.01em;
      }

      .mat-icon {
        @include font-color-3;
        width: 16px;
        height: 16px;
        font-size: 16px;
        padding: 0;
        border-radius: unset;
        flex-shrink: 0;
      }

      &.active {
        @include background-primary;
        @include font-primary;
        &:after {
          content: ' ';
          font-size: 0;
          height: 24px;
          width: 3px;
          border-radius: 7px 0 0 7px;
          background-color: $primary;
          position: absolute;
          top: calc(50% - 12px);
          right: 0;
        }
      }
      &.has-child {
        &.expanded {
          .expand-control {
            transform: rotate(180deg);
          }
        }
        .expand-control {
          position: absolute;
          right: 10px;
          top: calc(50% - 8px);
          display: flex;
          .mat-icon {
            width: 16px;
            height: 16px;
            line-height: 16px;
          }
        }
      }
      &.is-child {
        transition: height 0.2s ease-in-out;
        height: 0;
        overflow: hidden;
        &.child-expanded {
          height: 56px;
        }
      }
      .expand-control {
        display: none;
      }
    }
  }
}

mat-list-item.mat-mdc-list-item.time-option {
  padding: 16px 10px;

  .mdc-list-item__primary-text {
    @include font-color-3;
    @include body4;
  }

  &.selected {
    @include background-primary;

    &:after {
      content: " ";
      font-size: 0;
      height: 24px;
      width: 3px;
      border-radius: 7px 0 0 7px;
      background-color: $primary;
      position: absolute;
      top: calc(50% - 12px);
      right: 0;
    }

    .mdc-list-item__primary-text {
      @include font-primary;
      @include body4-medium;
    }
  }
}
