@import "src/styles/mixin";

@-moz-keyframes rotate { 100% { -moz-transform: rotate(-360deg); } }
@-webkit-keyframes rotate { 100% { -webkit-transform: rotate(-360deg); } }
@keyframes rotate { 100% { -webkit-transform: rotate(-360deg); transform:rotate(-360deg); } }

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.loading {
  .loadable * {
    border-color: transparent!important;
    color: transparent!important;
    background-color: transparent!important;
    box-shadow: none!important;
  }
  .loadable {
    position: relative;
    color: transparent!important;
    border-color: transparent!important;
    background-color: transparent!important;
    box-shadow: none!important;
    pointer-events: none;
    user-select: none;
    &:after {
      position: absolute;
      background: linear-gradient(90deg, var($--gray-7), var($--gray-8), var($--gray-9), var($--gray-8), var($--gray-7));
      background-size: 400% 400%;
      animation: gradient 7s ease infinite;
      border-radius: 20px;
      content: " ";
      top: 10%;
      left: 0;
      width: 100%;
      height: 90%;
      transition: 2s linear all;
      min-height: unset;
    }

    .mat-badge {display: none}
  }
}