/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import url('https://fonts.googleapis.com/css2?family=Mulish&family=Work+Sans:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
// Plus imports for other components in your app.
@import './src/app/shared/directives/mat-table-responsive/mat-table-responsive.directive.scss';
@import './src/app/shared/directives/skeleton-loader/skeleton-loader.scss';

@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.snow.css';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`

@import "styles/fonts";
@import "styles/components/mat-button";
@import "styles/components/mat-card";
@import "styles/components/mat-checkbox";
@import "styles/components/mat-dialog";
@import "styles/components/mat-input";
@import "styles/components/mat-list";
@import "styles/components/mat-menu";
@import "styles/components/mat-calendar";
@import "styles/components/quill-ngx-dropzone";
@import "styles/components/full-calendar";

@include mat.all-component-typographies();
@include mat.core();


h1{
  i{
    color:#008b95
  }
}

/* style the scrollbar */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* style the scrollbar track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* style the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 5px;
}

@media (min-width: 900px) {
  .workspace-dashboard {
    position: fixed;
    top: 70px;
    right: 20px;
  }
}

@media (max-width: 899px) {
  .workspace-dashboard {
    position: relative;
  }
}

// Toolbar
mat-toolbar-row.mat-toolbar-row, mat-toolbar-row.mat-toolbar-single-row {
  height: 70px;
  background-color: #FFFFFF;
  padding: 0;
  justify-content: space-between;
}
mat-toolbar.main-toolbar-mobile {
  min-height: 57px;
  mat-toolbar-row.mat-toolbar-row, mat-toolbar-row.mat-toolbar-single-row {
    height: 57px;
  }
}
mat-sidenav.mat-drawer.mat-drawer-side {
  width: 200px;
  border-right: 1px solid #eff1f3;
  transition: width 0.3s ease-in-out;
}
.sidenav-navigation-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  .sidenav-footer {
    display: flex;
    flex-direction: column;
    padding: 25px 18px 25px 25px;
    row-gap: 16px;
    a {
      cursor: pointer;
      @include body4;
      @include font-color-3;
      &:hover {
        @include font-primary;
      }
    }
  }
}
a:hover svg circle, a:hover svg{
  fill: transparent;
}
mat-icon.mat-icon {
  fill: none;
}
.mat-mdc-icon-button.mat-mdc-button-base {
  padding: 0px;
  width: 40px;
  height: 40px;
}

.mat-mdc-icon-button {
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.mat-mdc-card-header {
  /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
  .mat-card-header-text {
    margin: 0;
    .mat-mdc-card-title {
      margin: 0;
    }
  }
}

.mat-form-field-inline-text {
  &.mat-mdc-form-field {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
  .mat-mdc-select {
    font-family: inherit;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-infix {
    border-top: 0;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-label-wrapper {
    display: none;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-underline {
    display: none;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  .mat-select-trigger {
    display: flex;
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    .mat-select-value {
      max-width: unset;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    .mat-select-arrow-wrapper {
      display: none;
    }
  }
}

.mat-mdc-table-form-field-inline-text {
  &.mat-mdc-form-field {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
  .mat-mdc-select {
    font-family: inherit;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-infix {
    border-top: 0;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-label-wrapper {
    display: none;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-underline {
    display: none;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  .mat-select-trigger {
    display: flex;
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    .mat-select-value {
      max-width: unset;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    .mat-select-arrow-wrapper {
      display: none;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-wrapper {
    padding-bottom: 0;
    background-color: white;
    border-radius: 10px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-outline {
    color: transparent;
  }
}

.sidebar-closed {
  mat-sidenav.mat-drawer.mat-drawer-side {
    width: 68px;
    //transition: width 0.3s ease-in-out;
  }
}

.widget-chart-container {
  g[ngx-charts-x-axis-ticks] {
    text {
      fill: #4E5D78;
    }
  }
}

.advanced-pie-legend {

  .total-value {
    @include h4-bold;
    @include font-color-1;
  }

  .total-label {
    @include h6;
    @include font-color-1;
  }

  .legend-items-container {

    .legend-items {
      overflow: hidden !important;
      white-space: unset !important;

      .legend-item {
        margin-bottom: 15px;
        &:focus {
          outline: none;
        }

        &:hover {
          color: #000;
          -webkit-transition: 0.2s;
          -moz-transition: 0.2s;
          transition: 0.2s;
        }

        .item-value {
          @include h5;
          @include font-color-1;
        }

        .item-label {
          @include body3-medium;
          @include font-color-3;
          opacity: 1;
        }

        .item-percent {
          @include body2;
          @include font-color-3;
          opacity: 1;
        }

        .item-color {
          border-left: 4px solid;
          width: 4px;
          height: 42px;
          float: left;
          margin-right: 7px;
        }
      }
    }
  }
}
.opacity-80 {
  opacity: 0.8;
}

@media (max-width: 768px) {
  .container {
    padding: 0 10px;
  }
}

@media (min-width: 768px) {
  .sidebar-closed {
    .container {
      //width: 700px;
      width: auto;
    }
  }
}


@media (min-width: 768px) {
  .container {
    //width: 550px;
    width: auto;
  }
}

.container {
  //width: 100%;
  padding: 0 30px;
  margin-top: 10px;
}

.container-1024 {
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
}

.profile-container {
  .pq-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;

    margin: 0 8px;

    width: 15px;
    height: 15px;
    @include body4;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    position: absolute;
    top: 3px;
    right: -7px;
    font-size: 7px;
    &.danger {
      background-color: #FF754C;
    }
  }
  .profile__language-button {
    @include font-primary;
    cursor: pointer;
    display: flex;
    align-items: center;
    @include h8;
    font-weight: 500;
    .mat-icon {
      margin-right: 6px;
      height: 20px;
      width: 20px;
    }
  }
}

.menu-language {
  padding: 0;
  button {
    border: none;
  }
  span {
    text-align: left;
  }
}

.mat-drawer-container {
  color: unset !important;
}

@media print {
  .toolbar-container {
    display: none!important;
  }
  .mat-sidenav {
    display: none!important;
  }
  .mat-sidenav-content {
    margin-left: 0!important;
    padding: 0!important;
  }
  .dashboard-container {
    flex-direction: column;
  }

  .no-print, .no-print *  {
      display: none !important;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-link-container {
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-list {
    .mat-mdc-tab-links {
      .mat-mdc-tab-link {
        @include h8;
        padding: 0 20px;
      }
    }
    .mat-ink-bar {
      height: 3px;
      border-radius: 6px 6px 0px 0px;
    }
  }
}

.mat-mdc-tab-nav-bar {
  border-bottom: 1px solid var($--gray-7, #dcdfe3);
}

.mat-mdc-icon-button .mat-icon.mail-icon svg {
  width: 20px !important;
  height: 20px !important;
}

.mat-mdc-icon-button.info-button svg {
  width: 18px !important;
  height: 18px !important;
}

.mat-select-search-inner-row,
.mat-mdc-menu-panel.mdc-menu-surface {
  background-color: white;
}

.mat-mdc-option {
  @include body3;
}

.property-filter mat-form-field,
.date-selector-wrapper mat-form-field,
.prospect-events-property-filter mat-form-field {
  .mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
    border: none;
  }

  .mdc-floating-label,
  .mat-mdc-form-field-subscript-wrapper { // for vertical alignment
    display: none !important;
  }

  // to make it look exactly like it looked before the angular update
  .mat-mdc-select-value {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.015em;
    width: fit-content;
  }

  .mat-mdc-select-arrow-wrapper {
    margin-left: 10px;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--outlined {
    padding: 0;
    background-color: transparent;
  }
}

.property-filter mat-form-field,
.prospect-events-property-filter mat-form-field {
  .mat-mdc-form-field-infix {
    width: fit-content;
  }
}
.mat-mdc-paginator {
  .mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
    border: none;
  }
}

// change default dropdown icon
.mat-mdc-select-arrow-wrapper {
  -webkit-mask-image: url("./assets/icons/Arrows, Diagrams/Arrow chevron down.svg");
  mask-image: url("./assets/icons/Arrows, Diagrams/Arrow chevron down.svg");
  background-color: var(--font-3);
  width: 24px;
}
.mat-mdc-select-arrow {
  opacity: 0 !important;
}
.hide-select-arrow .mat-mdc-select-arrow-wrapper {
  -webkit-mask-image: none;
  mask-image: none;
  background-color: transparent;
}

.mat-mdc-option.mdc-list-item {
  font-size: 14px;
}

.multi-select-panel-custom-width {
  min-width: 245px;
}

.logo-container .sidebar-control .mat-icon svg {
  width: 18px;
  height: 18px;
}

.logo-container .mat-mdc-icon-button.mat-mdc-button-base {
  padding: 5px !important;
}

.multi-select .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
  display: none;
}

.multi-select .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
  height: 10px;
}

body {
  .action-buttons-container {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 12px;

    .small-icon-button-outlined {
      height: 30px;
      width: 30px;
      min-width: 30px;
      padding: 0 0 1px 0;
      border-radius: 10px;
      mat-icon {
        margin: 0;
        height: 16px;
        width: 16px;
        line-height: 16px;
        font-size: 16px;
      }
    }
  }
}

.mat-mdc-tab-nav-bar {
  border-bottom: 1px solid var(--gray-7);
}

.mat-divider.mat-divider-horizontal {
  width: 100%;
}

.mat-divider.mat-divider-vertical {
  height: 20px;
}

.full-width-field {
  width: 100%;
}

/* Override autofill background color because it was not filling whole mat field*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: inherit !important;
  box-shadow: 0 0 0px 1000px white inset !important;
}