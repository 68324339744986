@import "src/styles/mixin";

// material styling gets imported after this sheet, so we need to increase the specifity of our styling instead of using !important everywhere
body {
  .mat-mdc-outlined-button.mat-unthemed {
    --mdc-outlined-button-label-text-color: var(--primary-700);
  }

  .mat-mdc-raised-button.mat-primary,
  .mat-mdc-unelevated-button.mat-primary {
    --mdc-filled-button-label-text-color: var(--font-white) !important; // text color for flat button
    --mdc-protected-button-label-text-color: var(--font-white) !important; // text color for raised button
  }

  .mat-mdc-button-base {
    padding: 0;
  }

  --mdc-text-button-container-shape: 8px;
  --mdc-outlined-button-container-shape: var(--mdc-text-button-container-shape);
  --mdc-filled-button-container-shape: var(--mdc-text-button-container-shape);

  .mat-mdc-button,
  .mat-mdc-raised-button,
  .mat-mdc-unelevated-button,
  .mat-mdc-outlined-button {
    &.mat-mdc-button-base {
      padding: 8px 30px;
      height: 45px;
    }

    &.mat-mdc-button-base.mat-button-size-small {
      height: 36px;
    }

    & > .mat-icon {
      height: 24px;
      width: 24px;
      line-height: 24px;
      font-size: 24px;
    }
  }

  mat-icon + .mdc-button__label {
    margin-top: 3px; // label was slightly off center with icon
  }

  .mdc-button__label {
    display: inline-flex;
    align-items: center;
    gap: 10px;
  }

  mat-button-toggle-group.pretty-toggle-group {
    column-gap: 2px;
    border-radius: 10px;
    border: 1px solid var($--gray-7, #dcdfe3);
    background: var($--background-4, #fff);
    padding: 4px;
    height: 48px;
    @include forms-button-medium;

    .mat-button-toggle-appearance-standard {
      border-radius: 8px;

      &.mat-button-toggle-checked {
        --mat-standard-button-toggle-selected-state-text-color: var(--font-white);
        --mat-standard-button-toggle-selected-state-background-color: var(--primary-700);
      }
    }

    --mat-standard-button-toggle-divider-color: none;

    mat-pseudo-checkbox {
      display: none;
    }
  }

  // mat toggle buttons
  mat-button-toggle-group:not(.pretty-toggle-group) {
    --mat-standard-button-toggle-selected-state-background-color: var(--primary-6);

    &.mat-button-toggle-group-appearance-standard {
      --mat-standard-button-toggle-text-color: var(--font-3);
      --mat-standard-button-toggle-shape: var(
        --mdc-text-button-container-shape
      );
    }

    .mat-button-toggle-button {
      @include body4-medium;
      @include accent-font-2;
    }
  }
}

// material button-toggle. copy and pasted from tenant portal. not taking effect
mat-button-toggle-group:not(.pretty-toggle-group) {
  border: 1px solid var($--gray-7);
  background-color: transparent;

  &.mat-button-toggle-group-appearance-standard {
    --mat-standard-button-toggle-shape: 8px;
    box-sizing: border-box;
  }
  &.mat-button-toggle-group-primary {
    --mat-standard-button-toggle-divider-color: var(--primary-5);

    &.ng-invalid.ng-touched {
      border-color: var($--accent-danger-1);
    }
    .mat-ripple-element {
      opacity: 0.1;
      background-color: var($--primary-1);
    }
    .mat-button-toggle-appearance-standard {
      background-color: var($--primary-6);
      &.mat-button-toggle-checked {
        background-color: var($--primary-5);
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
        .mat-button-toggle-focus-overlay {
          border-bottom: 0;
        }
      }
      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
      .mat-button-toggle-focus-overlay {
        background-color: var($--primary-1);
      }
    }
  }
  &.mat-button-toggle-group-flat-primary {
    --mat-standard-button-toggle-divider-color: var(--primary-1);

    &.ng-invalid.ng-touched {
      border-color: var($--accent-danger-1);
    }
    .mat-ripple-element {
      opacity: 0.1;
      background-color: var($--primary-2);
    }
    .mat-button-toggle-appearance-standard {
      background-color: var($--primary-6);
      &.mat-button-toggle-checked {
        background-color: var($--primary-1);
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
        .mat-button-toggle-focus-overlay {
          border-bottom: 0;
        }
      }
      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
      .mat-button-toggle-focus-overlay {
        background-color: var($--primary-1);
      }
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  &.mat-button-toggle-group-stroked {
    border: 1px solid var($--gray-7);
    background-color: transparent;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  &.mat-button-toggle-group-stroked-primary {
    border: 0;
    border-radius: 0;
    &.ng-invalid.ng-touched {
      border: 1px solid var($--accent-danger-1);
    }
    .mat-ripple-element {
      opacity: 0.1;
      background-color: var($--primary-2);
    }
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
    .mat-button-toggle {
      border-top: 1px solid var($--gray-7);
      border-right: 0;
      border-bottom: 1px solid var($--gray-7);
      border-left: 1px solid var($--gray-7);
      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &:last-child {
        border-right: 1px solid var($--gray-7);
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
    .mat-button-toggle + .mat-button-toggle {
      border-left: 1px solid var($--gray-7);
    }
    .mat-button-toggle-appearance-standard {
      background-color: var($--background-4);
      &.mat-button-toggle-checked {
        border: 1px solid var($--primary-1);
        background-color: var($--primary-6);
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
        .mat-button-toggle-focus-overlay {
          border-bottom: 0;
        }
      }
      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
      .mat-button-toggle-focus-overlay {
        background-color: var($--primary-1);
      }
    }
  }

  .mat-button-toggle-button {
    @include body4-medium;
    @include accent-font-2;
  }

  .mat-button-toggle {
    width: 100%;
  }
}


