@import url(../../ng1/bower_components/bootstrap/dist/css/bootstrap.css);
@import url(../../ng1/bower_components/bootstrap/dist/css/bootstrap-theme.css);
@import url(textAngular.css);
@import url(../../assets/ng1/fontawesome/css/all.min.css);
@import url("https://fonts.googleapis.com/css?family=Open+Sans");

.tabs {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  font-weight: 300;
  font-size: 1em;
  margin-bottom: 15px;
}

/* Nav */
.tabs nav {
  text-align: center;
}

.tabs nav ul {
  position: relative;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  margin: 0 auto;
  padding: 0;
  max-width: 1200px;
  list-style: none;
  -ms-box-orient: horizontal;
  -ms-box-pack: center;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}

@media screen and (max-width: 485px) {
  .nav-tenant .navbar .navbar-nav.icon-md li:first-child {
    display: none !important;
  }
}

.tabs nav ul li {
  position: relative;
  z-index: 1;
  display: block;
  margin: 0 .25em;
  text-align: center;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.tabs nav a {
  position: relative;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 2;
}

.tabs nav a span {
  vertical-align: middle;
  font-size: 0.6em;
}

.tabs nav li.tab-current a {
  color: #74777b;
}

.tabs nav a:focus {
  outline: none;
}

/* Icons */
.tabs .icon::before {
  z-index: 10;
  display: inline-block;
  margin: 0 0.4em 0 0;
  vertical-align: middle;
  text-transform: none;
  font-weight: normal;
  font-variant: normal;
  font-size: 0.9em;
  font-family: inherit;
  line-height: 1;
  speak: none;
  -webkit-backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 58em) {
  .tabs nav a.icon span {
    /*  zoom:70%;*/
    font-size: 11px;
  }
  .tabs nav a:before {
    margin-right: 0;
  }
  .tabs .icon::before {
    font-size: 24px;
  }
  .tabs-style-fillup nav ul li a {
    padding: 0.25em 0 0.2em 0;
  }
}

/* Content */
.content-wrap {
  position: relative;
}

.content-wrap section {
  display: none;
  margin: 0 auto;
  padding: 1em;
  max-width: 1200px;
  text-align: center;
}

.content-wrap section.content-current {
  display: block;
}

.content-wrap section p {
  margin: 0;
  padding: 0.75em 0;
  color: rgba(40, 44, 42, 0.05);
  font-weight: 900;
  font-size: 3em;
  line-height: 1;
}

/* Fallback */
.no-js .content-wrap section {
  display: block;
  padding-bottom: 2em;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}

.no-flexbox nav ul {
  display: block;
}

.no-flexbox nav ul li {
  min-width: 15%;
  display: inline-block;
}

/*****************************/
/* Fill up */
/*****************************/
.tabs-style-fillup nav ul li a {
  padding: 0.35em 0;
  /*border-right: 1px solid #008b95;
  border-bottom: 1px solid #008b95 !important;*/
  line-height: 1;
  /*-webkit-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;*/
}

.tabs-style-fillup nav ul li:last-child a {
  border: none;
}

.tabs-style-fillup nav ul li.tab-current {
  z-index: 100;
}

/*.tabs-style-fillup nav ul li.tab-current a {
  color: #fff; }*/

.tabs-style-fillup nav ul li a::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  height: calc(100% + 1px);
  /*border: 1px solid #008b95;
  background: #008b95;
  content: '';*/
  /*-webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);*/
}

.tabs-style-fillup nav ul li.tab-current a::after {
  /*-webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);*/
}

.tabs-style-fillup nav ul li a span,
.tabs-style-fillup .icon::before {
  /*-webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);*/
}

/*.tabs-style-fillup nav ul li a span {
  display: block;
  font-weight: 300;
  font-size: 0.4em;
  line-height: 1.5; }*/

.tabs-style-fillup .icon::before {
  display: block;
  margin: 0;
}

.tabs-style-fillup nav ul li.tab-current a span,
.tabs-style-fillup li.tab-current .icon::before {
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
}

.tabs nav ul li a span {
  font-family: 'Open Sans', sans-serif;
}

.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

#main-region {
  padding-bottom: 15px;
}

.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.main-container.non-active {
  background: white !important;
}

.main-container.active {
  min-height: 100%;
  background: -webkit-linear-gradient(top, rgba(2, 73, 83, 0.6), rgba(2, 73, 83, 0.6)) fixed, url(../../assets/ng1/images/architectural-abstracts-blur.jpg) no-repeat 0% 50% fixed;
  background-size: cover;
  /*-webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;*/
}

.not-tenant.main-container.active {
  min-height: 100%;
  background: -webkit-linear-gradient(top, rgba(2, 73, 83, 0.6), rgba(2, 73, 83, 0.6)) fixed, url(../../assets/ng1/images/architectural-abstracts-blur.jpg) no-repeat 0% 50% fixed;
  background-size: cover;
  transform-style: preserve-3d;
}

@media screen and (max-width: 768px) {
  .main-container.active .container-fluid.content > .container {
    max-width: 100%;
  }
}

@media screen and (min-width: 769px) and (orientation: landscape) {
  .main-container.active .container-fluid.content > .container {
    max-width: 960px;
  }
}

@media screen and (min-height: 576px) {
  .main-container.active .container-fluid.content {
    position: relative;
    top: 40%;
    transform: translateY(-40%);
    -webkit-transform: translateY(-40%);
  }
}

object.svg-image-object {
  width: 38px;
  height: 28px;
}

ul.navbar-nav li a img {
  width: 38px;
  height: 28px;
}

#tenant-view-subview > div > div.row {
  margin: 0px;
}

div.main-container {
  padding-bottom: 100px;
}

body {
  min-height: 103px;
}

.loginBox {
  border: 1px solid gainsboro;
  padding: 17px;
  box-shadow: 0 0 5px gainsboro;
  margin-bottom: 20px;
  margin-top: -10px;
  border-radius: 10px;
}

.smallTable {
  margin-bottom: 10px !important;
  font-size: 90%;
  border: 1px solid #f2f2f2;
}

.smallTable tbody tr td {
  padding: 4px !important;
  white-space: normal !important;
}

div.some-div {
  background: #FFFFFF;
  background: -moz-linear-gradient(top, #FFFFFF 0%, #DDDDDD 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFFFFF), color-stop(100%, #DDDDDD));
  background: -webkit-linear-gradient(top, #FFFFFF 0%, #DDDDDD 100%);
  background: -o-linear-gradient(top, #FFFFFF 0%, #DDDDDD 100%);
  background: -ms-linear-gradient(top, #FFFFFF 0%, #DDDDDD 100%);
  background: linear-gradient(to bottom, #FFFFFF 0%, #DDDDDD 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$top-color', endColorstr='$bottom-color', GradientType=0);
}

@media (max-width: 767px) {
  div.some-div {
    background: #FFFFFF;
    background: -moz-linear-gradient(top, #FFFFFF 0%, #0088CC 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFFFFF), color-stop(100%, #0088CC));
    background: -webkit-linear-gradient(top, #FFFFFF 0%, #0088CC 100%);
    background: -o-linear-gradient(top, #FFFFFF 0%, #0088CC 100%);
    background: -ms-linear-gradient(top, #FFFFFF 0%, #0088CC 100%);
    background: linear-gradient(to bottom, #FFFFFF 0%, #0088CC 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$top-color', endColorstr='$bottom-color', GradientType=0);
  }
  div.some-div h1 {
    font-size: 50px;
  }
}

.region-loading-indicator {
  display: block;
  margin: 20px auto;
  text-align: center;
}

.creditcard-types div {
  height: 50px;
}

.creditcard-types img {
  height: 45px;
  opacity: 0.5;
}

.creditcard-types img.active {
  zoom: 1.2;
  opacity: 1;
  position: absolute;
  top: -5px;
}

@font-face {
  font-family: 'icomoon';
  src: url("/assets/fonts/icomoon.eot?jt41lc");
  src: url("/assets/fonts/icomoon.eot?jt41lc#iefix") format("embedded-opentype"), url("/assets/fonts/icomoon.ttf?jt41lc") format("truetype"), url("/assets/fonts/icomoon.woff?jt41lc") format("woff"), url("/assets/fonts/icomoon.svg?jt41lc#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Parisienne";
  src: local("Parisienne"), url('/assets/fonts/Parisienne-regular/Parisienne-Regular.ttf') format('truetype');
  font-weight: bold;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-pay-now:before {
  content: "\e901";
}

.icon-dashboard-paynow:before {
  content: "\e902";
}

.icon-maintenance:before {
  content: "\e900";
}

.icon-tenants:before {
  content: "\e610";
}

.icon-icon-view:before {
  content: "\e611";
}

.icon-dashboard-history-28:before {
  content: "\e630";
}

.icon-dashboard-paynow-28:before {
  content: "\e600";
}

.icon-bubble:before {
  content: "\e601";
}

.icon-access-time:before {
  content: "\e602";
}

.icon-cancel:before {
  content: "\e603";
}

.icon-cancel-icon:before {
  content: "\e604";
}

.icon-chevron-left:before {
  content: "\e605";
}

.icon-chevron-right:before {
  content: "\e606";
}

.icon-clock-3 .path1:before {
  content: "\e607";
  color: #4a525f;
}

.icon-clock-3 .path2:before {
  content: "\e608";
  margin-left: -1em;
  color: rgba(255, 255, 255, 0.05);
}

.icon-clock-3 .path3:before {
  content: "\e609";
  margin-left: -1em;
  color: #d1d1d1;
}

.icon-clock-3 .path4:before {
  content: "\e60a";
  margin-left: -1em;
  color: #4a525f;
}

.icon-clock-3 .path5:before {
  content: "\e60b";
  margin-left: -1em;
  color: #4a525f;
}

.icon-clock-3 .path6:before {
  content: "\e60c";
  margin-left: -1em;
  color: #4a525f;
}

.icon-clock-3 .path7:before {
  content: "\e60d";
  margin-left: -1em;
  color: #b84b4a;
}

.icon-clock-3 .path8:before {
  content: "\e60e";
  margin-left: -1em;
  color: #b84b4a;
}

.icon-company:before {
  content: "\e60f";
}

.icon-dashboard-mail:before {
  content: "\e612";
}

.icon-dashboard-methods:before {
  content: "\e902";
}

.icon-dashboard-properties:before {
  content: "\e614";
}

.icon-dashboard-reports:before {
  content: "\e615";
}

.icon-dashboard-rewards:before {
  content: "\e616";
}

.icon-dashboard-schedule:before {
  content: "\e617";
}

.icon-dashboard-tenants:before {
  content: "\e618";
}

.icon-dashboard-users:before {
  content: "\e619";
}

.icon-footer-payquad-logo:before {
  content: "\e61a";
}

.icon-ic_cancel:before {
  content: "\e61b";
}

.icon-icon-accept .path1:before {
  content: "\e61c";
  color: #024953;
}

.icon-icon-accept .path2:before {
  content: "\e61d";
  margin-left: -1em;
  color: white;
}

.icon-icon-attention .path1:before {
  content: "\e61e";
  color: #ea501b;
}

.icon-icon-attention .path2:before {
  content: "\e61f";
  margin-left: -1em;
  color: white;
}

.icon-icon-attention .path3:before {
  content: "\e620";
  margin-left: -1em;
  color: white;
}

.icon-icon-chevron-down .path1:before {
  content: "\e621";
  color: #024953;
}

.icon-icon-chevron-down .path2:before {
  content: "\e622";
  margin-left: -1em;
  color: white;
}

.icon-icon-chevron-left .path1:before {
  content: "\e623";
  color: #024953;
}

.icon-icon-chevron-left .path2:before {
  content: "\e624";
  margin-left: -1em;
  color: white;
}

.icon-icon-chevron-right .path1:before {
  content: "\e625";
  color: #024953;
}

.icon-icon-chevron-right .path2:before {
  content: "\e626";
  margin-left: -1em;
  color: white;
}

.icon-icon-edit .path1:before {
  content: "\e627";
  color: #024953;
}

.icon-icon-edit .path2:before {
  content: "\e628";
  margin-left: -1em;
  color: white;
}

.icon-icon-info .path1:before {
  content: "\e629";
  color: #024953;
}

.icon-icon-info .path2:before {
  content: "\e62a";
  margin-left: -1em;
  color: white;
}

.icon-icon-info .path3:before {
  content: "\e62b";
  margin-left: -1em;
  color: white;
}

.icon-icon-plus .path1:before {
  content: "\e62c";
  color: #024953;
}

.icon-icon-plus .path2:before {
  content: "\e62d";
  margin-left: -1em;
  color: white;
}

.icon-icon-social-facebook:before {
  content: "\e62e";
}

.icon-icon-social-twitter:before {
  content: "\e62f";
}

.icon-chevron-right2:before {
  content: "\e631";
}

.icon-plus:before {
  content: "\ea0a";
}

.icon-paperplane:before {
  content: "\e632";
}

.icon-sm .icomoon-icon {
  display: block;
}

.icon-sm.faded {
  color: #cccccc;
}

.icon-sm.faded svg circle {
  fill: #cccccc;
}

ul.pagination li a img {
  vertical-align: top;
}

ul.pagination li.disabled a {
  background-color: #f2f2f2;
}

ul.pagination li.disabled a img {
  opacity: 0.3;
}

.pagination li.active a {
  text-decoration: underline;
  background-color: transparent;
}

.pagination li.active a:hover {
  background-color: #00c7cc;
}

#tenant-nav-dashboard li {
  position: relative;
}

#tenant-nav-dashboard li .badge {
  position: absolute;
  top: 3px;
  right: -10px;
  background: #97d629;
  color: #222222;
  border: 3px solid #FFFFFF;
}

body#dashboard a.dashboard-icon {
  position: relative;
}

body#dashboard a.dashboard-icon .badge {
  position: absolute;
  top: 5px;
  right: 0px;
  font-size: 2em;
  background-color: #97d629;
  border: 2px solid #222222;
  color: #222222;
}

body#dashboard .row.menu a.dashboard-icon, body#dashboard .row.menu a.dashboard-icon img {
  color: #FFFFFF;
}

.row.menu a.dashboard-icon .svg-icon {
  height: 100px;
  fill: #FFFFFF;
  color: #FFFFFF;
  display: block;
}

.row.menu a.dashboard-icon .svg-icon.mail-icon {
  background-image: url("../../assets/ng1/svg/dashboard-mail.svg");
  background-repeat: no-repeat;
}

@media print {
  .no-print, .no-print * {
    display: none !important;
  }
}

.align-center, .menu, .notes, .buttons {
  text-align: center;
}

.align-left {
  text-align: left;
}

.align-right, .items-page {
  text-align: right;
}

.vmiddle {
  vertical-align: middle;
}

.vbottom {
  vertical-align: bottom;
}

.nav, .pagination, .carousel, .panel-title a {
  cursor: pointer;
}

.tabulation {
  margin: 0px 20px;
  padding: 7px;
}

input[type="checkbox"].min-width {
  min-width: 1px;
  width: initial;
}

.clearfix {
  padding: 15px;
}

h1 {
  font-size: 2em;
  margin-top: 5px;
}

h2 {
  font-size: 1.55em;
  margin-top: 5px;
}

h3 {
  font-size: 1.2em;
  margin-top: 5px;
}

h1, h2, h3, h4, h5, h6 {
  vertical-align: baseline;
}

div.checkbox > label > .checkbox-warning {
  color: #a94442;
  margin-left: 10px;
  display: none;
}

.has-error div.checkbox > label > .checkbox-warning {
  display: inline-block;
}

.form-group span.form-control-feedback {
  right: 36px;
}

input.large-input {
  padding: 15px;
  height: auto;
}

form.large-inputs {
  padding-right: 20px;
}

form.large-inputs input {
  padding: 15px;
  height: auto;
}

form.large-inputs input {
  width: 100%;
}

.large-inputs-group input, .large-inputs-group select {
  padding: 10px;
  height: auto;
  width: 100%;
}

div.bootstrap-tagsinput {
  padding-left: 0px;
}

div.test-class-blah {
  background-color: #800080;
}

/** for auto complete inputs **/
tags-input .tags .tag-item {
  background: #40AAB2 !important;
}

tags-input .tags .tag-item .remove-button {
  color: white !important;
}

/* Tables */
.content-scroll {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
}

.content-scroll .table {
  max-width: initial;
}

.table td.smallTD {
  padding-left: 0;
  margin: 0;
}

.table {
  background-color: #f2f2f2;
  margin-bottom: 0px;
}

.table thead tr th {
  padding: 15px 20px;
  border-right: 1px solid #ffffff;
  vertical-align: middle;
  white-space: nowrap;
}

.table thead tr th:last-child {
  border-right: none;
}

.table tbody {
  border-top: 4px solid #ffffff;
}

.panel-body .table tbody {
  border-top: none;
}

.table tbody tr:nth-child(even) {
  background-color: #ffffff;
}

.table tbody tr.footer {
  border-top: 2px solid #cccccc;
  font-weight: bold;
  background-color: #f2f2f2;
  text-align: center;
}

.table tbody tr th {
  border-top: none;
  white-space: nowrap;
}

.table tbody tr th.row-header {
  color: #cccccc;
}

.table tbody tr td {
  border-top: none;
  padding: 8px 20px;
  white-space: nowrap;
}

.table tbody tr td.currency {
  text-align: right;
}

.table .row-header {
  padding-left: 10px;
  width: 5%;
}

.table tbody tr th.header {
  color: #cccccc;
  border: 1px solid #dadada;
}

.table .col-icon {
  width: 22px;
  padding-right: 10px;
}

.table .col-icon .icon-sm {
  width: 22px;
  height: 22px;
}

.table .col-icon .icon-sm svg {
  width: 100%;
  height: 100%;
  vertical-align: top;
}

.table .col-icon .icon-sm .icomoon-icon {
  font-size: 22px;
  vertical-align: middle;
}

.table .col-icon .icon-sm:hover {
  color: #00c7cc;
}

/*.table.edit-inline {
  margin-bottom: 0;
  border-bottom-right-radius: 25px;
}*/
.edit-inline input[type="text"], .edit-inline input[type="url"], .edit-inline input[type="email"] {
  border: none;
  border-bottom: 2px solid transparent;
  background-color: transparent;
  -webkit-appearance: none;
  border-radius: 0;
  width: 100%;
}

.edit-inline select {
  border: none;
  border-bottom: 2px solid transparent;
  background-color: transparent;
  -webkit-appearance: none;
  border-radius: 0;
}

.table.edit-inline input[type="text"]:focus:not(:read-only), .table.edit-inline input[type="url"]:focus:not(:read-only), .table.edit-inline input[type="email"]:focus:not(:read-only) {
  outline: none;
  border-bottom: 2px solid #00c7cc;
}

.table.edit-inline select:focus {
  outline: none;
  border-bottom: 2px solid #00c7cc;
}

td.ckeditor-field {
  padding: 0px !important;
  max-width: 500px;
}

thead tr th {
  color: #008b95;
}

.filtered-table {
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -5px;
}

/* END Tables */
/* Forms */
.large-inputs-group input[type=checkbox] {
  width: auto !important;
}

.row.form.normal-margins {
  margin-left: 0px;
  margin-right: 0px;
}

select.form-control {
  -webkit-appearance: none;
  background-image: url("../../assets/ng1/svg/icon-arrow-down.svg");
  background-position: right 10px top 50%;
  background-repeat: no-repeat;
  background-color: #f2f2f2;
}

.form-control {
  border-color: #024953;
  background-color: #f2f2f2;
  float: left;
}

form.email textarea:focus {
  outline: none;
  border-bottom: 2px solid #00c7cc;
}

form.email textarea {
  border: none;
  border-bottom: 2px solid transparent;
  background-color: transparent;
  -webkit-appearance: none;
  border-radius: 0;
  width: 100%;
  float: left;
}

.form-control:focus {
  border-color: #00c7cc;
  background-color: #ffffff;
}

.form-control.form-icon {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/" version="1.1" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 20 20" enable-background="new 0 0 20 20" xml:space="preserve"><defs/><path id="Search_Icon_1_" fill="#024953" d="M19.7 16.9l-0.1-0.1 0 0 -4.8-4.8c0.7-1.2 1.1-2.5 1.1-3.9 0-4.4-3.5-8-7.9-8 0 0 0 0-0.1 0C3.6 0 0 3.5 0 7.9c0 4.4 3.5 8 7.9 8 0 0 0 0 0.1 0 1.5 0 2.8-0.4 4-1.1l4.8 4.8 0 0 0.1 0.1c0.4 0.4 1 0.4 1.3 0l1.6-1.6C20.1 17.8 20.1 17.2 19.7 16.9zM12.1 12.1c-1.1 1.1-2.6 1.7-4.2 1.7h0C4.6 13.8 2 11.2 2 7.9 2 4.7 4.7 2 7.9 2h0c1.6 0 3.1 0.6 4.2 1.8 1.1 1.1 1.7 2.6 1.7 4.2C13.8 9.5 13.2 11 12.1 12.1z"/></svg>');
  padding-right: 40px;
  background-position: right 10px top 50%;
  background-repeat: no-repeat;
  background-size: auto 50%;
}

.input-group .input-group-addon {
  border-color: #024953;
  background-color: #f2f2f2;
  /*
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px;
*/
}

.btn-icon {
  vertical-align: middle;
  background-color: #008b95;
}

.btn-icon.btn-default {
  color: #ffffff;
}

.btn-icon.btn-default:hover, .btn-icon.btn-default:active {
  color: #ffffff;
  background-color: #00c7cc;
  border-color: #009599;
}

.btn-icon .download {
  display: inline;
  padding: 10px 20px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/" version="1.1" x="0px" y="0px" width="29.3px" height="20px" viewBox="0 0 29.3 20" enable-background="new 0 0 29.3 20" xml:space="preserve"><defs/><path fill="#FFFFFF" d="M23.9 7.9c0-0.2 0-0.5 0-0.7 0-4-3.2-7.2-7.2-7.2v10.1H18c0.6 0 1 0.7 0.6 1.3l-3.4 4.3c-0.3 0.4-0.9 0.4-1.2 0l-3.4-4.3c-0.4-0.5 0-1.3 0.6-1.3h1.2V1.5c-1.1 0.8-1.9 2-2.4 3.3C9.2 4.4 8.2 4.2 7.1 4.4 5.4 4.7 4 6 3.7 7.7c-0.2 1-0.1 2 0.3 2.9 -2.3 0.4-4.1 2.4-4 4.9C0.1 18 2.4 20 5 20h18c3.2 0 6.1-2.4 6.3-5.6C29.5 11 27.1 8.2 23.9 7.9z"/></svg>');
  background-repeat: no-repeat;
  background-position: 0 50%;
}

.btn {
  padding: 10px 20px;
  border-radius: 6px;
  text-transform: uppercase;
  background-image: none;
  min-width: 100%;
}

.btn-default {
  border-color: #024953;
  color: #024953;
  background-image: none;
}

.btn-default:hover, .btn-default:active {
  border-color: #00c7cc;
  background-color: #f2f2f2;
  color: #00c7cc;
}

.btn-primary {
  background-color: #008b95;
}

.btn-primary:hover, .btn-primary:active {
  background-color: #00c7cc;
  border-color: #04a1b7;
}

.checkbox {
  margin: 0 0 10px;
  text-align: left;
}

.annotation {
  margin: 10px 0 10px;
  color: #ea501b;
  display: block;
}

.table .annotation {
  margin: 0;
  display: inline;
  font-weight: normal;
}

.icon-addon {
  padding-left: 0;
  margin: 8px 0;
}

.icon-addon a {
  display: inline-block;
  vertical-align: middle;
}

.form-item {
  margin: 8px 0;
  padding-left: 0;
}

label {
  font-weight: normal;
}

.list-group.message li.system {
  background-color: #00c7cc;
}

.list-group.message li.management {
  background-color: #97d629;
}

.list-group.message li.read {
  background-color: #f2f2f2;
}

.output-field {
  padding: 10px;
  height: auto;
  width: 100%;
}

/* END Forms */
.ckeditor-field .btn, #bulk-message-textarea  .btn, #message-textarea .btn {
  min-width: 1px;
}

.datepicker .dropdown-menu .btn {
  padding: 5px 10px;
  min-width: 1px;
}

.remove-button {
  position: absolute;
  top: 0;
  right: 10px;
}

.icon-cancel.danger {
  color: #c12e2a;
  cursor: pointer;
}

/* Dropdowns */
.dropdown .btn {
  text-transform: none;
}

.dropdown .btn-default {
  background-color: #f2f2f2;
  color: #024953;
  border-color: #024953;
  padding: 0;
  padding-left: 10px;
}

.dropdown .btn-default span {
  border-left: thin solid #cccccc;
  display: inline-block;
  padding: 6px 12px;
  margin-left: 10px;
}

.dropdown .btn-default span path {
  stroke: #024953;
}

.dropdown .dropdown-menu {
  background-color: #f2f2f2;
  border-color: #024953;
}

.dropdown .dropdown-menu li a:hover, .dropdown .dropdown-menu li a:active {
  background-color: #ffffff;
  border-color: #00c7cc;
}

/* END Dropdowns */
/* Pagination */
@media screen and (max-width: 380px) {
  .pagination li a {
    width: 25px !important;
  }
}

.pagination li a {
  text-decoration: none !important;
  color: #00c7cc !important;
}

.pagination li.active a {
  color: white !important;
  background-color: #00c7cc !important;
  border-radius: 20px;
}

.pagination {
  background-color: #f2f2f2;
  border-radius: 24px;
  display: inline-block;
}

.pagination li:first-child a, .pagination li:last-child a {
  width: auto;
  height: 28px;
  padding: 6px;
  box-sizing: content-box;
  margin: 0;
}

.pagination li:first-child a:hover, .pagination li:last-child a:hover, .pagination li:first-child a:active, .pagination li:last-child a:active {
  background-color: transparent;
}

.pagination li:first-child a:hover circle, .pagination li:last-child a:hover circle, .pagination li:first-child a:active circle, .pagination li:last-child a:active circle {
  fill: #00c7cc !important;
}

.pagination li:first-child a {
  border-radius: 24px 0 0 24px;
}

.pagination li:last-child a {
  border-radius: 0 24px 24px 0;
}

.pagination li a {
  width: 32px;
  height: 32px;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 4px 0;
  line-height: 32px;
  text-align: center;
}

.pagination li a:link, .pagination li a:visited {
  color: #00c7cc;
}

.pagination.not_mobile li a:hover, .pagination.not_mobile li a:active {
  background-color: #00c7cc;
  border-radius: 20px;
  color: #ffffff !important;
}

/* END Pagination */
ul.pagination {
  text-align: center;
  margin: 0px auto;
}

/* Tabbing */
.nav-tabs {
  border-bottom: none;
  margin-bottom: 20px;
}

.nav-tabs li {
  margin-bottom: 0;
  border-left: 1px solid #024953;
}

.nav-tabs.clear li {
  margin-bottom: 0;
  border-left: 0;
}

.nav-tabs li a {
  margin-right: 5px;
  border: none;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  /*border-bottom: 4px solid #cccccc;
  padding: 17px 10px 13px 10px;*/
}

.nav-tabs li a:hover {
  background-color: transparent;
  border-color: #cccccc;
}

.nav-tabs li a .inline-notification-bubble {
  margin-left: 10px;
  margin-right: 0;
}

.nav-tabs li.last {
  border-right: 1px solid #024953;
}

.nav-tabs li.alt {
  border: none;
  background-color: #cccccc;
  border-right: 1px solid #ffffff;
}

.nav-tabs li.alt a {
  font-weight: bold;
  border-bottom: none;
  padding: 17px 10px;
}

.nav-tabs li.alt a:last-child {
  border-radius: 0 27px 27px 0;
}

.nav-tabs li.alt a:link, .nav-tabs li.alt a:visited {
  color: #ffffff;
}

.nav-tabs li.alt a:hover, .nav-tabs li.alt a:active {
  border-radius: 0;
  background-color: transparent;
  color: #00c7cc;
}

.nav-tabs li.alt.last a {
  padding-right: 8px;
}

.nav-tabs li.alt.last, .nav-tabs li.alt.last a:hover {
  border-right: none;
  border-radius: 0 27px 27px 0;
}

.nav-tabs li.active a {
  border: none;
  border-bottom: 4px solid #00c7cc;
  margin-bottom: -4px;
  color: #00c7cc;
  margin-right: 0;
}

.nav-tabs li.active a:hover {
  border: none;
  border-bottom: 4px solid #00c7cc;
  margin-bottom: -4px;
}

.nav-tabs li.alt.active a {
  border-bottom: 0;
  background-color: #cccccc;
}

.nav-tabs.clear li.paid a {
  color: #97d629;
}

.nav-tabs.clear li.unpaid a {
  color: #024953;
}

.nav-tabs.clear li.declined a {
  color: #ea501b;
}

.nav-tabs.clear li.paid.active a {
  border-bottom: 4px solid #97d629;
  margin-bottom: -4px;
  color: #97d629;
}

.nav-tabs.clear li.unpaid.active a {
  border-bottom: 4px solid #024953;
  margin-bottom: -4px;
  color: #024953;
}

.nav-tabs.clear li.declined.active a {
  border-bottom: 4px solid #ea501b;
  margin-bottom: -4px;
  color: #ea501b;
}

@media screen and (max-width: 320px) and (orientation: portrait) {
  .nav-tabs .inline-notification-bubble {
    /* display: none; */
    font-size: 0;
    padding: 5px;
    vertical-align: middle;
  }
  .nav-tabs li a {
    padding: 5px 10px;
  }
  .nav-tabs li.alt {
    margin-top: 4px;
  }
  .nav-tabs li.alt a {
    padding: 7px 10px;
  }
  .nav-tabs li.alt.first {
    clear: left;
    border-left: none;
  }
  .nav-tabs li.alt.last {
    border-radius: 0 6px 6px 0;
  }
}

@media screen and (max-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .nav-tabs li a {
    /* padding: 5px 10px; */
    padding: 6px 10px 4px 10px;
  }
  .nav-tabs li.alt {
    clear: none;
  }
  .nav-tabs li.alt a {
    padding: 7px 10px;
  }
  .nav-tabs li.alt.last {
    border-radius: 0 6px 6px 0;
  }
  .nav-tabs .inline-notification-bubble {
    /* display: none; */
    font-size: 0;
    padding: 5px;
    vertical-align: middle;
  }
}

/* END Tabbing */
.navbar .navbar-nav.icon-md li > a svg {
  margin-top: -2px;
  /* width: 50px; */
  /* max-width: 50%; */
  height: 28px;
  width: 38px;
}

.top-nav {
  background: none;
  border: 0;
  //max-height: 64px;
  //overflow-x: scroll;
  //-ms-overflow-style: none;
}

//.top-nav::-webkit-scrollbar{
//  display: none;
//}
//
//.top-nav > .container-fluid{
//  min-width: 1442px;
//}

.top-nav a {
  font-size: 1em;
}

.top-nav .logo {
  font-size: 1.5em;
  /* padding-left: $space-medium; */
}

.top-nav .icon-md {
  font-size: 1.5em;
}

.top-nav .icon-md a {
  padding: 10px 0;
}

.navbar.navbar-default {
  box-shadow: none;
  /*
		&.sub-page.top-nav {
			background: -webkit-linear-gradient(top, rgba(2, 73, 83, 0.6), rgba(2, 73, 83, 0.6)) fixed, url(../../assets/ng1/images/architectural-abstracts-blur.jpg) no-repeat 0% 50% fixed;
			background-repeat: no-repeat;
			background-position: 50% 0;
			background-size: cover;
			border-radius: 0;
		}
*/
}

#dashboard .navbar.navbar-default {
  background-color: rgba(255, 255, 255, 0.4);
}

#dashboard .navbar.navbar-default ul#tenant-nav-dashboard, #dashboard .navbar.navbar-default ul#management-nav-dashboard, #dashboard .navbar.navbar-default ul#admin-nav-dashboard {
  display: none;
}

.navbar.navbar-default .container-fluid {
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: none;
  border-bottom: 1px solid #008b95;
}

.navbar.navbar-default .navbar-toggle {
  border-color: #ffffff;
}

.navbar.navbar-default .navbar-toggle:hover, .navbar.navbar-default .navbar-toggle:active {
  background-color: #00c7cc;
}

.navbar.navbar-default .navbar-toggle:hover .icon-bar, .navbar.navbar-default .navbar-toggle:active .icon-bar {
  background-color: #ffffff;
}

.navbar.navbar-default .navbar-toggle .icon-bar {
  background-color: #cccccc;
}

.navbar.navbar-default .navbar-nav .open a.dropdown-toggle {
  background-color: #cccccc;
}

.navbar.navbar-default .navbar-nav .open .dropdown-menu {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.navbar.navbar-default .navbar-nav .dropdown-menu {
  border: none;
  background-color: #cccccc;
  box-shadow: none;
  border-top: 2px solid transparent;
}

.navbar.navbar-default .navbar-nav .dropdown-menu li > a {
  color: #024953;
}

.navbar.navbar-default .navbar-nav .dropdown-menu li > a:hover, .navbar.navbar-default .navbar-nav .dropdown-menu li > a:active {
  color: #ffffff;
  background-color: #00c7cc;
  background-image: none;
}

.navbar.navbar-default .navbar-collapse {
  padding: 0 10px;
}

.navbar.navbar-default .navbar-collapse .dropdown-menu {
  border-radius: 0;
}

.navbar .navbar-header a {
  text-shadow: none;
}

.navbar .navbar-header a:link, .navbar .navbar-header a:visited {
  color: #4d4d4d;
}

.navbar .navbar-header a:hover, .navbar .navbar-header a:active {
  color: #cccccc;
}

.navbar .navbar-nav.icon-md {
  /* padding-left: 20px; */
  margin: 0;
}

.navbar .navbar-nav.icon-md li {
  float: left;
  padding: 0 10px;
}

nav li a.active svg, nav li a.active svg path {
  fill: #00c7cc;
}

/* and (orientation: portrait)*/
@media screen and (max-width: 430px) {
  .navbar .navbar-nav.icon-md li {
    padding: 0 6px;
  }
  h1 {
    font-size: 1.4em;
  }
  .sections span, .steps span {
    font-size: 16px !important;
  }
  .sections .active span.step {
    padding: 5px 10px !important;
    font-size: 14px !important;
  }
}

.navbar .navbar-nav.icon-md li > a {
  padding: 10px 0 5px 0;
}

#management-nav-dashboard li a{
  text-align: center;
}

.manager-navbar-label{
  font-size: 9px !important;
  text-transform: uppercase;
  font-family: Open Sans !important;
  padding: 0;
  text-align: center;
  display: block;
  color: black;
}

.navbar .navbar-nav.icon-md li > a:link, .navbar .navbar-nav.icon-md li > a:visited {
  color: #008b95;
}

.navbar .navbar-nav.icon-md li > a:hover, .navbar .navbar-nav.icon-md li > a:active {
  color: #00c7cc;
}

.navbar .navbar-nav.icon-md li > a svg {
  margin-top: -2px;
}

.navbar .navbar-nav.icon-md li > a i {
  font-size: 28px;
}

.navbar-nav > li > a {
  padding-top: 15px;
}

@media screen and (max-width: 320px) and (orientation: portrait) {
  .navbar .navbar-nav.icon-md li > a svg {
    width: 32px;
  }
}

.navbar .navbar-nav.icon-md li > a svg:hover path, .navbar .navbar-nav.icon-md li > a svg:active path {
  fill: #00c7cc;
}

.navbar .navbar-nav li.dropdown > a, .navbar .navbar-nav li.sign-out > a {
  color: #4d4d4d;
  text-shadow: none;
}

.navbar .navbar-nav li.dropdown > a:hover, .navbar .navbar-nav li.sign-out > a:hover, .navbar .navbar-nav li.dropdown > a:active, .navbar .navbar-nav li.sign-out > a:active {
  color: #00c7cc;
}

.navbar-nav.icon-md li.icon2 > a svg:hover path, .navbar-nav.icon-md li.icon2 > a.active svg path {
  fill: #cccccc !important;
}

.navbar-nav.icon-md li.icon3 > a svg:hover path, .navbar-nav.icon-md li.icon3 > a.active svg path {
  fill: #97d629 !important;
}

.navbar-nav.icon-md li.icon4 > a svg:hover path, .navbar-nav.icon-md li.icon4 > a.active svg path {
  fill: #ea501b !important;
}

.navbar-nav.icon-md li.icon5 > a svg:hover path, .navbar-nav.icon-md li.icon5 > a.active svg path {
  fill: #024953 !important;
}

.navbar-nav.icon-md li.icon5 > a svg:hover polygon, .navbar-nav.icon-md li.icon5 > a.active svg polygon {
  fill: #024953 !important;
}

.navbar-nav.icon-md li.icon6 > a svg:hover path, .navbar-nav.icon-md li.icon6 > a.active svg path {
  fill: #d49e14 !important;
}

.navbar-nav.icon-md li.icon7 > a svg:hover path, .navbar-nav.icon-md li.icon7 > a.active svg path {
  fill: #dcbfbf !important;
}

.navbar-nav.icon-md li.icon7 > a svg:hover polygon, .navbar-nav.icon-md li.icon7 > a.active svg polygon {
  fill: #dcbfbf !important;
}

/* Accordion Menu */
.panel-group {
  margin-bottom: 5px;
}

.panel-group .panel {
  border: 1px solid #dddddd;
}

.panel-group .panel .panel-heading {
  height: 50px;
  background-color: transparent;
  padding: 10px 10px 10px 20px;
  background-image: none;
  border-bottom: 1px solid #aaaaaa;
  background: #FFFFFF;
  background: -moz-linear-gradient(top, #FFFFFF 0%, #EEEEEE 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFFFFF), color-stop(100%, #EEEEEE));
  background: -webkit-linear-gradient(top, #FFFFFF 0%, #EEEEEE 100%);
  background: -o-linear-gradient(top, #FFFFFF 0%, #EEEEEE 100%);
  background: -ms-linear-gradient(top, #FFFFFF 0%, #EEEEEE 100%);
  background: linear-gradient(to bottom, #FFFFFF 0%, #EEEEEE 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$top-color', endColorstr='$bottom-color', GradientType=0);
}

.panel-group .panel .panel-heading .panel-title {
  line-height: 30px;
  color: #024953;
  font-weight: bold;
  font-size: 16px;
}

.panel-group .panel .panel-heading .panel-title .text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 70%;
  display: inline-block;
}

.panel-group .panel .panel-heading .panel-title .accordion-toggle.collapsed .icon-sm img, .panel-title .accordion-toggle.collapsed img {
  transition-duration: 0.2s;
  transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  -webkit-transition-duration: 0.2s;
  /* Opera, Chrome, and Safari */
}

.panel-group .panel .panel-heading .panel-title .accordion-toggle .icon-sm img, .panel-title .accordion-toggle img {
  transition-duration: 0.2s;
  transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  /* IE 9 */
  -webkit-transform: rotate(-90deg);
  -webkit-transition-duration: 0.2s;
  /* Opera, Chrome, and Safari */
}

.panel-title .accordion-toggle span.icon-sm {
  transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  /* IE 9 */
  -webkit-transform: rotate(-90deg);
  /* Opera, Chrome, and Safari */
}

.panel-title .accordion-toggle.collapsed span.icon-sm {
  transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Opera, Chrome, and Safari */
}

.panel-title .accordion-toggle .icomoon-icon {
  font-size: 28px;
}

.accordion-toggle.collapsed span.glyphicon-chevron-right {
  transition-duration: 0.2s;
  transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  -webkit-transition-duration: 0.2s;
  /* Opera, Chrome, and Safari */
}

.accordion-toggle span.glyphicon-chevron-right {
  transition-duration: 0.2s;
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  /* IE 9 */
  -webkit-transform: rotate(90deg);
  -webkit-transition-duration: 0.2s;
  /* Opera, Chrome, and Safari */
}

.panel-group .panel .panel-heading .panel-title .accordion-toggle:hover circle {
  fill: #00c7cc;
}

.panel-group .panel .panel-heading .panel-title:after {
  content: "";
  clear: both;
  display: table;
}

.panel-group .panel .panel-collapse .panel-body {
  padding: 0px;
  overflow: hidden;
}

.panel-group .panel .panel-collapse .panel-body.edit-content-table {
  padding: 0px;
}

.panel-group .panel .panel-collapse .panel-body.edit-content-table .row-header {
  color: #cccccc;
}

/* END Accordion Menu */
.wizard {
  position: relative;
  top: -20px;
}

.wizard .sections {
  background-color: rgba(242, 242, 242, 0.5);
  border-bottom: 1px solid #CCCCCC;
}

.row.filter {
  min-height: 64px;
}

.row.filter .add-filter-col {
  position: relative;
  top: 3px;
}

#filter-by-input {
  width: 100%;
}

.filter-tag {
  margin-top: 8px !important;
  position: relative;
  display: inline-block;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #024953;
  padding: 6px 32px 6px 10px !important;
  background: #FDFDFD;
  background: -moz-linear-gradient(top, #FDFDFD 0%, #E0E0E0 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FDFDFD), color-stop(100%, #E0E0E0));
  background: -webkit-linear-gradient(top, #FDFDFD 0%, #E0E0E0 100%);
  background: -o-linear-gradient(top, #FDFDFD 0%, #E0E0E0 100%);
  background: -ms-linear-gradient(top, #FDFDFD 0%, #E0E0E0 100%);
  background: linear-gradient(to bottom, #FDFDFD 0%, #E0E0E0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$top-color', endColorstr='$bottom-color', GradientType=0);
  background-size: 100% 100px;
  background-position: 0px 0px;
  -webkit-transition: all 100ms linear;
  -moz-transition: all 100ms linear;
  -ms-transition: all 100ms linear;
  -o-transition: all 100ms linear;
  transition: all 100ms linear;
}

.filter-tag .tag-name {
  text-transform: uppercase;
}

.filter-tag .tag-close-btn {
  width: 16px;
  height: 16px;
  display: block;
  font-size: 16px;
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -7px;
  color: #AAAAAA;
  cursor: pointer;
}

.filter-tag .tag-close-btn:hover {
  color: #777777;
}

.filter-tag:hover {
  color: #00c7cc;
  background-position: 0px -30px;
}

.companyinfo {
  min-height: 15vh;
  height: 100%;
  overflow: hidden;
}

.companyicon {
  margin: 20px;
}

.companyicon img {
  max-width: 300px;
  max-height: 300px;
  width: 100%;
  height: auto;
}

@media screen and (max-width: 400px) and (orientation: portrait) {
  .companyicon img {
    max-width: 150px;
    max-height: 150px;
  }
}

.companymsg {
  min-height: 120px;
}

.companymsg h4 {
  text-align: justify;
}

.bg-img {
  background: -webkit-linear-gradient(left, rgba(255, 0, 0, 0.9), rgba(255, 0, 0, 0));
  /*Safari 5.1-6*/
  background: -o-linear-gradient(to left, rgba(255, 0, 0, 0.9), rgba(255, 0, 0, 0));
  /*Opera 11.1-12*/
  background: -moz-linear-gradient(rgba(255, 0, 0, 0.9), rgba(255, 0, 0, 0));
  /*Fx 3.6-15*/
  background: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 0, 0, 0) 100%);
  /*Standard*/
}

.property-header-chart {
  height: 60px;
  width: auto;
  padding: 0px !important;
  text-align: center;
}

.property-header-chart > div {
  height: 60px;
  width: 100%;
}

.chart-row > div {
  margin-top: -15px;
}

.chart-row > div > div {
  text-align: center;
}

.legendLabel {
  padding-left: 7px;
}

#placeholder .flot-base {
  width: 100% !important;
}

.footer {
  background-color: #008b95;
  background: url("../../assets/ng1/images/architectural2.jpg") no-repeat 0% 50% fixed;
  background-size: cover;
  margin-top: -100px;
  min-height: 160px;
}

.footer .payquad {
  padding-top: 20px;
  height: 50px;
}

.footer .payquad .brand {
  margin-top: 8px;
  margin-bottom: 10px;
  text-align: left;
}

.footer .payquad .info {
  color: #cccccc;
  margin-bottom: 10px;
}

.footer .payquad .info a:link, .footer .payquad .info a:visited {
  color: #cccccc;
}

.footer .payquad .info a:hover, .footer .payquad .info a:active {
  color: #00c7cc;
}

.footer .social {
  padding: 25px 0px;
}

.footer .social a:not(.info) {
  margin-left: 14px;
}

.footer img {
  max-height: 50px;
  margin-top: -10px;
}

.footer span {
  color: #cccccc;
}

.footer .nav.nav-pills {
  margin-top: 5px;
  display: inline-block;
}

.footer .nav.nav-pills a {
  color: #cccccc;
  padding: 5px 10px;
}

.footer .nav.nav-pills a:hover, .footer .nav.nav-pills a:active {
  color: #00c7cc;
}

@media screen and (max-width: 568px) {
  .footer .payquad .info p:not(.align-left), .footer .social {
    text-align: center;
  }
  .footer .brand .col-xs-8 {
    float: none;
  }
}

@media screen and (max-width: 400px) and (orientation: portrait) {
  .footer .nav.nav-pills li {
    display: block;
    float: none;
  }
}

@media screen and (min-width: 992px) {
  .footer .brand {
    padding-top: 25px;
  }
  .footer .social {
    padding-top: 45px;
  }
}

body#dashboard div.container div.row.menu {
  border-bottom: 1px solid #FFFFFF;
}

.dashboard-icon {
  display: block;
}

.dashboard-icon span {
  display: block;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  margin-bottom: 10px;
}

.dashboard-icon:hover span, .dashboard-icon:active span {
  color: #00c7cc;
}

.dashboard-icon:hover svg path, .dashboard-icon:active svg path {
  fill: #00c7cc;
}

/* colors */
.second-dashboard-icon:hover span, .second-dashboard-icon:active span {
  color: #cccccc !important;
}

.second-dashboard-icon:hover svg path, .second-dashboard-icon:active svg path {
  fill: #cccccc !important;
}

.third-dashboard-icon:hover span, .third-dashboard-icon:active span {
  color: #97d629 !important;
}

.third-dashboard-icon:hover svg path, .third-dashboard-icon:active svg path {
  fill: #97d629 !important;
}

.fourth-dashboard-icon:hover span, .fourth-dashboard-icon:active span {
  color: #ea501b !important;
}

.fourth-dashboard-icon:hover svg path, .fourth-dashboard-icon:active svg path {
  fill: #ea501b !important;
}

.fifth-dashboard-icon:hover span, .fifth-dashboard-icon:active span {
  color: #024953 !important;
}

.fifth-dashboard-icon:hover svg path, .fifth-dashboard-icon:active svg path {
  fill: #024953 !important;
}

.fifth-dashboard-icon:hover svg polygon, .fifth-dashboard-icon:active svg polygon {
  fill: #024953 !important;
}

.sixth-dashboard-icon:hover span, .sixth-dashboard-icon:active span {
  color: #d49e14 !important;
}

.sixth-dashboard-icon:hover svg path, .sixth-dashboard-icon:active svg path {
  fill: #d49e14 !important;
}

.seventh-dashboard-icon:hover span, .fifth-dashboard-icon:active span {
  color: #dcbfbf !important;
}

.seventh-dashboard-icon:hover svg path, .fifth-dashboard-icon:active svg path {
  fill: #dcbfbf !important;
}

.seventh-dashboard-icon:hover svg polygon, .fifth-dashboard-icon:active svg polygon {
  fill: #dcbfbf !important;
}

.dashboard-icon svg {
  max-width: 100%;
}

.dashboard-icon svg path {
  fill: #ffffff;
}

.notes .icon-s [class^="icon-"] {
  font-size: 1.5em;
  color: #fff;
}

.dashboard-icon [class^="icon-"] {
  color: #fff;
  font-size: 6em;
}

@media screen and (max-width: 480px) {
  .notes .icon-s [class^="icon-"] {
    font-size: 1.2em;
  }
}

.dashboard-msg {
  top: 40px !important;
  right: 26% !important;
}

.t_align_r {
  text-align: right;
}

.extension_balance_details_total {
  text-align: right;
  padding: 8px 20px !important;
  font-weight: bold;
}

.extension_balance_details button {
  margin-top: 20px;
}

.bg-info {
  background-color: #008B95;
  color: white;
  padding: 10px !important;
  text-align: center;
}

.bg-danger {
  background-color: #ea501b !important;
  font-weight: bold;
  color: white !important;
  text-align: center;
}

#messages-pane-title {
  margin-top: 0px;
}

#message-list-layout-view {
  border-left: none;
}

.management #message-list-layout-view {
  border-left: 1px solid #DDDDDD;
}

.legend svg {
  margin-right: 2px;
}

#message-folders-view ul li {
  display: block !important;
  width: 100%;
  clear: both;
}

.isread {
  width: 24px;
  height: 24px;
  padding: 2px;
}

.isread .icon-icon-accept {
  font-size: 12px;
}

.system-msg {
  background-color: #00c7cc;
}

.landlorg-msg {
  background-color: #cccccc;
}

#billing-address-form.show {
  display: block;
}

.jslider div.jslider-bg i.range {
  width: 0 !important;
}

.jslider table td, .jslider table th {
  padding: 0 !important;
}

.jslider div.jslider-bg {
  margin-top: -3px;
}

.header-payments-table {
  position: relative;
  top: -5px;
}

.header-payments-table tr td {
  padding-left: 30px;
  font-size: 1em;
}

.header-payments-table tr th {
  padding-left: 20px;
  font-size: 0.75em;
}

.header-payments-table tr td:first-child {
  padding-left: 20px;
}

.header-payments-table tr th:nth-child(2) {
  padding-left: 20px;
}

.row#charts-row {
  border-top: 1px solid #222222;
  position: relative;
}

.row#charts-row a.close-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  display: block;
  z-index: 5000;
}

.line-chart-container {
  margin-top: 12px;
  width: 100%;
  text-align: center;
}

.line-chart {
  height: 100px;
  width: 100%;
}

.pie-chart-container {
  height: 160px;
  width: 100%;
}

.pie-chart {
  height: 160px;
  width: 100%;
}

.recurring-icon {
  height: 15px;
  margin-right: 2px;
  margin-top: -2px;
  opacity: 0.8;
}

.tenants-panel {
  border-top: 1px solid #dddddd;
}

.tenants-panel .form div {
  padding: 0px;
}

.tenants-panel .panel, .tenants-panel .panel-default {
  padding: 0px;
  border: none;
  margin: 0px;
  margin-top: 0px;
}

.tenants-panel .panel + .panel {
  margin-top: 0px;
}

.tenants-panel .panel .panel-heading {
  height: 50px;
  background-color: transparent;
  padding: 10px 10px 10px 20px;
  background-image: none;
  border-bottom: 1px solid #DDDDDD;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  background: #FCFCFC;
  background: -moz-linear-gradient(top, #FCFCFC 0%, #EEEEEE 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FCFCFC), color-stop(100%, #EEEEEE));
  background: -webkit-linear-gradient(top, #FCFCFC 0%, #EEEEEE 100%);
  background: -o-linear-gradient(top, #FCFCFC 0%, #EEEEEE 100%);
  background: -ms-linear-gradient(top, #FCFCFC 0%, #EEEEEE 100%);
  background: linear-gradient(to bottom, #FCFCFC 0%, #EEEEEE 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$top-color', endColorstr='$bottom-color', GradientType=0);
}

.tenants-panel .panel .panel-collapse {
  border-left: 1px solid #DDDDDD;
  border-right: 1px solid #DDDDDD;
  border-bottom: 1px solid #DDDDDD;
}

.tenants-panel .panel .panel-collapse .panel-body {
  border-top: none;
}

.extension-balance span.icon {
  color: #fff;
}

nav [class^="icon-"], nav [class*=" icon-"] {
  font-size: 28px;
  //line-height: 0.6;
}

#qmessage, .navbar-bubble {
  position: absolute;
  top: 5px;
  font-size: 14px;
  background: #97d629;
  color: #FFFFFF;
  right: -7px;
  padding: 0px 5px;
  border-radius: 5px;
}

#qmessage {
  padding: 5px;
  visibility: hidden;
  pointer-events: none;
}

/* PayQuad UI Kit Theme */
/* Colour Variables */
/* Global Styles */
body {
  color: #024953;
}

a:link, a:visited {
  color: #024953;
}

a:hover, a:active {
  color: #00c7cc;
  text-decoration: none;
}

.inline-notification-bubble {
  padding: 10px;
  margin: 0 20px;
  background-color: #ea501b;
  display: inline;
  text-align: center;
  /* vertical-align: middle; */
  color: #FFFFFF;
  border-radius: 20px;
  font-weight: bold;
}

/* END Global Styles */
/* UI Iconography */
.icon-sm {
  width: 28px;
  height: 28px;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
}

.icon-sm.accept svg circle {
  fill: #97d629;
}

.icon-sm:hover circle {
  fill: #00c7cc;
}

.panel-title .icon-sm {
  float: right;
  margin-left: 20px;
}

.annotation .icon-sm {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.annotation .icon-sm span {
  vertical-align: middle;
  display: inline-block;
  margin-top: -4px;
}

.annotation .icon-sm:hover circle {
  fill: #ea501b;
}

.annotation .icon-sm svg {
  max-width: 100%;
  height: inherit;
}

/* END UI Iconography */
/* Accordion Menu */
/* END Accordion Menu */
/* Tables */
.content-scroll {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
}

.content-scroll .table {
  max-width: initial;
}

/* END Tables */
/* Tabbing */
/* END Tabbing */
/* Pagination */
.pagination {
  background-color: #f2f2f2;
  border-radius: 24px;
  display: inline-block;
}

.pagination li:first-child a, .pagination li:last-child a {
  width: auto;
  height: 28px;
  padding: 6px;
  box-sizing: content-box;
  margin: 0;
}

.pagination li:first-child a:hover, .pagination li:first-child a:active, .pagination li:last-child a:hover, .pagination li:last-child a:active {
  background-color: transparent;
}

.pagination li:first-child a:hover circle, .pagination li:first-child a:active circle, .pagination li:last-child a:hover circle, .pagination li:last-child a:active circle {
  fill: #00c7cc;
}

.pagination li:first-child a {
  border-radius: 24px 0 0 24px;
}

.pagination li:last-child a {
  border-radius: 0 24px 24px 0;
}

.pagination li a {
  width: 32px;
  height: 32px;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 4px 0;
  line-height: 32px;
  text-align: center;
}

.pagination li a:link, .pagination li a:visited {
  color: #024953;
}

.pagination.not_mobile li a:hover, .pagination.not_mobile li a:active, .pagination.not_mobile li a:focus {
  background-color: #00c7cc;
  border-radius: 20px;
  color: #FFFFFF;
}

/* END Pagination */
/* Dropdowns */
.dropdown .btn {
  text-transform: none;
}

.dropdown .btn-default {
  background-color: #f2f2f2;
  color: #024953;
  border-color: #024953;
  padding: 0;
  padding-left: 10px;
}

.dropdown .btn-default span {
  border-left: thin solid #cccccc;
  display: inline-block;
  padding: 6px 12px;
  margin-left: 10px;
}

.dropdown .btn-default span path {
  stroke: #024953;
}

.dropdown .dropdown-menu {
  background-color: #f2f2f2;
  border-color: #024953;
}

.dropdown .dropdown-menu li a:hover, .dropdown .dropdown-menu li a:active {
  background-color: #FFFFFF;
  border-color: #00c7cc;
}

/* END Dropdowns */
/* Forms */
select.form-control {
  -webkit-appearance: none;
  background-image: url("../../assets/ng1/svg/icon-arrow-down.svg");
  background-position: right 10px top 50%;
  background-repeat: no-repeat;
  background-color: #f2f2f2;
}

.form-control {
  border-color: #024953;
  background-color: #f2f2f2;
  /* margin: 8px 0; */
  /* 	border-radius: 6px; */
}

.form-control:focus {
  border-color: #00c7cc;
  background-color: #FFFFFF;
}

.form-control.form-icon {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/" version="1.1" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 20 20" enable-background="new 0 0 20 20" xml:space="preserve"><defs/><path id="Search_Icon_1_" fill="#024953" d="M19.7 16.9l-0.1-0.1 0 0 -4.8-4.8c0.7-1.2 1.1-2.5 1.1-3.9 0-4.4-3.5-8-7.9-8 0 0 0 0-0.1 0C3.6 0 0 3.5 0 7.9c0 4.4 3.5 8 7.9 8 0 0 0 0 0.1 0 1.5 0 2.8-0.4 4-1.1l4.8 4.8 0 0 0.1 0.1c0.4 0.4 1 0.4 1.3 0l1.6-1.6C20.1 17.8 20.1 17.2 19.7 16.9zM12.1 12.1c-1.1 1.1-2.6 1.7-4.2 1.7h0C4.6 13.8 2 11.2 2 7.9 2 4.7 4.7 2 7.9 2h0c1.6 0 3.1 0.6 4.2 1.8 1.1 1.1 1.7 2.6 1.7 4.2C13.8 9.5 13.2 11 12.1 12.1z"/></svg>');
  padding-right: 40px;
  background-position: right 10px top 50%;
  background-repeat: no-repeat;
  background-size: auto 50%;
}

.input-group .input-group-addon {
  border-color: #024953;
  background-color: #f2f2f2;
  /*
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
    */
}

.btn-icon {
  vertical-align: middle;
  background-color: #008b95;
}

.btn-icon.btn-default {
  color: #FFFFFF;
}

.btn-icon.btn-default:hover, .btn-icon.btn-default:active {
  color: #FFFFFF;
  background-color: #00c7cc;
  border-color: #009599;
}

.btn-icon .download {
  display: inline;
  padding: 10px 20px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/" version="1.1" x="0px" y="0px" width="29.3px" height="20px" viewBox="0 0 29.3 20" enable-background="new 0 0 29.3 20" xml:space="preserve"><defs/><path fill="#FFFFFF" d="M23.9 7.9c0-0.2 0-0.5 0-0.7 0-4-3.2-7.2-7.2-7.2v10.1H18c0.6 0 1 0.7 0.6 1.3l-3.4 4.3c-0.3 0.4-0.9 0.4-1.2 0l-3.4-4.3c-0.4-0.5 0-1.3 0.6-1.3h1.2V1.5c-1.1 0.8-1.9 2-2.4 3.3C9.2 4.4 8.2 4.2 7.1 4.4 5.4 4.7 4 6 3.7 7.7c-0.2 1-0.1 2 0.3 2.9 -2.3 0.4-4.1 2.4-4 4.9C0.1 18 2.4 20 5 20h18c3.2 0 6.1-2.4 6.3-5.6C29.5 11 27.1 8.2 23.9 7.9z"/></svg>');
  background-repeat: no-repeat;
  background-position: 0 50%;
}

.btn {
  padding: 10px 20px;
  border-radius: 20px;
  text-transform: uppercase;
  background-image: none;
  min-width: 100%;
}

.btn-default {
  border-color: #024953;
  color: #024953;
  background-image: none;
}

.btn-default:hover, .btn-default:active {
  border-color: #00c7cc;
  background-color: #f2f2f2;
  color: #00c7cc;
}

.btn-primary {
  background-color: #008b95;
}

.btn-primary:hover, .btn-primary:active {
  background-color: #00c7cc;
  border-color: #04a1b7;
}

.checkbox {
  margin: 0 0 10px;
}

.annotation {
  margin: 10px 0 10px;
  color: #ea501b;
  display: block;
}

.table .annotation {
  margin: 0;
  display: inline;
  font-weight: normal;
}

.icon-addon {
  padding-left: 0;
  margin: 8px 0;
}

.icon-addon a {
  display: inline-block;
  vertical-align: middle;
}

.form-item {
  margin: 8px 0;
}

label {
  font-weight: normal;
}

.list-group.message li.system {
  background-color: #00c7cc;
}

.list-group.message li.management {
  background-color: #97d629;
}

.list-group.message li.read {
  background-color: #f2f2f2;
}

/* END Forms */
/* Font Colors*/
.grey {
  color: #cccccc;
}

.dark-teal {
  color: #024953;
}

.medium-teal {
  color: #008b95;
}

.light-teal {
  color: #00c7cc;
}

.light-grey {
  color: #f2f2f2;
}

.green {
  color: #97d629;
}

.orange {
  color: #ea501b;
}

.white {
  color: #FFFFFF;
}

/* Button */
.minonepx {
  min-width: 1px;
}

/* Data Tables */
.dataTables_wrapper .dataTables_processing {
  height: 65px !important;
  font-size: 1.5em !important;
}

.dataTables_filter {
  display: none;
}

.reportsContainer .dataTables_filter {
  display: block;
}

table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc, table.dataTable.rowClickable td {
  cursor: pointer;
}

table.dataTable td a {
  border-bottom: 1px solid;
}

table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc, table.dataTable thead .sorting_asc_disabled, table.dataTable thead .sorting_desc_disabled {
  background-repeat: no-repeat;
  background-position: center right;
}

table.dataTable thead .sorting {
  background-image: url("https://portal.payquad.com/assets/images/sort_both.png");
}

table.dataTable thead .sorting_asc {
  background-image: url("https://portal.payquad.com/assets/images/sort_asc.png");
}

table.dataTable thead .sorting_desc {
  background-image: url("https://portal.payquad.com/assets/images/sort_desc.png");
}

table.dataTable thead .sorting_asc_disabled {
  background-image: url("https://portal.payquad.com/assets/images/sort_asc_disabled.png");
}

table.dataTable thead .sorting_desc_disabled {
  background-image: url("https://portal.payquad.com/assets/images/sort_desc_disabled.png");
}

table.dataTable th.dt-left, table.dataTable td.dt-left {
  text-align: left;
}

table.dataTable th.dt-center, table.dataTable td.dt-center, table.dataTable td.dataTables_empty {
  text-align: center;
}

table.dataTable th.dt-right, table.dataTable td.dt-right {
  text-align: right;
}

table.dataTable th.dt-justify, table.dataTable td.dt-justify {
  text-align: justify;
}

table.dataTable th.dt-nowrap, table.dataTable td.dt-nowrap {
  white-space: nowrap;
}

table.dataTable thead th.dt-head-left, table.dataTable thead td.dt-head-left, table.dataTable tfoot th.dt-head-left, table.dataTable tfoot td.dt-head-left {
  text-align: left;
}

table.dataTable thead th.dt-head-center, table.dataTable thead td.dt-head-center, table.dataTable tfoot th.dt-head-center, table.dataTable tfoot td.dt-head-center {
  text-align: center;
}

table.dataTable thead th.dt-head-right, table.dataTable thead td.dt-head-right, table.dataTable tfoot th.dt-head-right, table.dataTable tfoot td.dt-head-right {
  text-align: right;
}

table.dataTable thead th.dt-head-justify, table.dataTable thead td.dt-head-justify, table.dataTable tfoot th.dt-head-justify, table.dataTable tfoot td.dt-head-justify {
  text-align: justify;
}

table.dataTable thead th.dt-head-nowrap, table.dataTable thead td.dt-head-nowrap, table.dataTable tfoot th.dt-head-nowrap, table.dataTable tfoot td.dt-head-nowrap {
  white-space: nowrap;
}

table.dataTable tbody th.dt-body-left, table.dataTable tbody td.dt-body-left {
  text-align: left;
}

table.dataTable tbody th.dt-body-center, table.dataTable tbody td.dt-body-center {
  text-align: center;
}

table.dataTable tbody th.dt-body-right, table.dataTable tbody td.dt-body-right {
  text-align: right;
}

table.dataTable tbody th.dt-body-justify, table.dataTable tbody td.dt-body-justify {
  text-align: justify;
}

table.dataTable tbody th.dt-body-nowrap, table.dataTable tbody td.dt-body-nowrap {
  white-space: nowrap;
}

/*
 * Control feature layout
 */
.dataTables_wrapper {
  position: relative;
  clear: both;
  zoom: 1;
}

.dataTables_paginate {
  background: #f2f2f2;
  border-radius: 32px;
}

.dataTables_wrapper .dataTables_length {
  float: left;
}

.dataTables_wrapper .dataTables_filter {
  float: right;
  text-align: right;
}

.dataTables_wrapper .dataTables_filter input {
  margin-left: 0.5em;
}

.dataTables_wrapper .dataTables_info {
  clear: both;
  float: left;
  padding-top: 0.755em;
}

.dataTables_wrapper .dataTables_paginate {
  float: right;
  text-align: right;
  padding-top: 0.25em;
  margin-top: 15px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  box-sizing: border-box;
  border-radius: 20px;
  outline: none !important;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  color: #00c7cc;
  border: 1px solid transparent;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: white;
  background-color: white;
  background: #00c7cc;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  cursor: default;
  color: #666;
  border: 1px solid transparent;
  background: #00c7cc;
  box-shadow: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: white !important;
  background: #00c7cc;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
  outline: none;
  background: #00c7cc;
}

.dataTables_wrapper .dataTables_paginate .ellipsis {
  padding: 0 1em;
}

.dataTables_paginate .previous, .dataTables_paginate .next {
  background: #024953 !important;
  color: white !important;
  font-size: 19px;
  padding: 3px 7px !important;
}

.dataTables_paginate .previous {
  margin-right: 12px;
}

.dataTables_paginate .next {
  margin-left: 8px !important;
}

.dataTables_wrapper .dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 40px;
  margin-left: -50%;
  margin-top: -25px;
  padding-top: 20px;
  text-align: center;
  font-size: 1.2em;
  background-color: white;
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(25%, rgba(255, 255, 255, 0.9)), color-stop(75%, rgba(255, 255, 255, 0.9)), color-stop(100%, rgba(255, 255, 255, 0)));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  /* FF3.6+ */
  background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  /* IE10+ */
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  /* Opera 11.10+ */
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  /* W3C */
}

.dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter, .dataTables_wrapper .dataTables_info, .dataTables_wrapper .dataTables_processing, .dataTables_wrapper .dataTables_paginate {
  color: #333;
}

.dataTables_wrapper .dataTables_scroll {
  clear: both;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
  -webkit-overflow-scrolling: touch;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody th > div.dataTables_sizing, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody td > div.dataTables_sizing {
  height: 0;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
}

.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom: 1px solid #111;
}

.dataTables_wrapper.no-footer div.dataTables_scrollHead table, .dataTables_wrapper.no-footer div.dataTables_scrollBody table {
  border-bottom: none;
}

.dataTables_wrapper:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0;
}

@media screen and (max-width: 767px) {
  .dataTables_wrapper .dataTables_info, .dataTables_wrapper .dataTables_paginate {
    float: none;
    text-align: center;
  }
  .dataTables_wrapper .dataTables_paginate {
    margin-top: 0.5em;
  }
}

@media screen and (max-width: 640px) {
  .dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter {
    float: none;
    text-align: center;
  }
  .dataTables_wrapper .dataTables_filter {
    margin-top: 0.5em;
  }
}

/* LESS Document */
a:link {
  text-decoration: none;
}

/*
ul {
	list-style-type: none;
}
*/
.sections {
  /* padding: 15px inherit; */
  margin-bottom: 20px;
  background-color: #f2f2f2;
  padding: 10px inherit;
}

.sections .section {
  display: inline-block;
  vertical-align: middle;
  padding: 5px 0;
  margin-right: 10px;
}

.sections span {
  color: #cccccc;
  vertical-align: middle;
}

.sections span.step {
  font-size: 12px;
  background-color: #cccccc;
  padding: 10px 20px;
  border-radius: 6px;
  color: #FFFFFF;
  display: none;
}

.sections .active span {
  color: #00c7cc;
}

.sections .active span.step {
  font-size: 12px;
  background-color: #00c7cc;
  padding: 10px 20px;
  border-radius: 6px;
  color: #FFFFFF;
  display: inline;
}

.steps span {
  color: #cccccc;
}

.steps span.active {
  color: #00c7cc;
  font-weight: bold;
}

.sections span, .steps span {
  margin-right: 10px;
  white-space: nowrap;
  font-size: 18px;
}

.inactive {
  color: #eeeeee;
}

.menu {
  margin-top: 35px;
  border-bottom: solid black 1px;
}

.menu .icon-l {
  font-size: 6em;
}

.menu p {
  font-size: 1em;
  margin: 35px 0;
}

.notes {
  margin-top: 35px;
}

.notes .icon-s {
  font-size: 3em;
}

.form div:not(.icon-caret) {
  padding: 5px;
}

.checkbox {
  text-align: left;
}


.icon-xs {
  font-size: 1.5em;
  vertical-align: bottom;
}

.reset-password {
  margin-top: 100px;
}

.border-right {
  border-right: solid black 1px;
}

.border-left {
  border-left: solid black 1px;
}

.mail {
  padding: 0px;
}

.items-page {
  margin: 10px 0;
}

.pagination {
  margin: 0;
}

.filter {
  border-top: solid black 1px;
  border-bottom: solid black 1px;
}

.invites {
  margin-top: 35px;
}

.icon-md {
  font-size: 2em;
  padding: 0;
}

/* Layout Updates */
@font-face {
  font-family: 'Ubuntu-Regular';
  src: url("../../assets/ng1/fonts/ubuntu/Ubuntu-R.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu-Light';
  src: url("../../assets/ng1/fonts/ubuntu/Ubuntu-L.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu-Bold';
  src: url("../../assets/ng1/fonts/ubuntu/Ubuntu-B.ttf");
  font-weight: 900;
  font-style: normal;
}

html, body {
  min-height: 100%;
  font-family: 'Open Sans', sans-serif;
}

.sign-in, .sign-up {
  margin-bottom: 20px;
}

.sign-in ul, .sign-up ul {
  margin-bottom: 20px;
}

.sign-in .forgot-pass, .sign-up .forgot-pass {
  text-align: right;
  font-size: 12px;
}

.sign-in .remember-me, .sign-up .remember-me {
  font-size: 12px;
}

.sign-in h1, .sign-up h1 {
  padding: 20px 20px 20px 0;
  border-bottom: 1px solid #FFFFFF;
  margin-bottom: 20px;
}

.corp-brand {
  text-align: center;
  padding: 40px 0;
  border-bottom: 1px solid #f2f2f2;
  font-size: 32px;
}

.dashboard-icon {
  display: block;
}

.dashboard-icon span {
  display: block;
  color: #FFFFFF;
  font-size: 16px;
  text-align: center;
  margin-bottom: 10px;
}

.dashboard-icon:hover span, .dashboard-icon:active span {
  color: #00c7cc;
}

.dashboard-icon:hover svg path, .dashboard-icon:active svg path {
  fill: #00c7cc;
}

.dashboard-icon svg {
  max-width: 100%;
}

.dashboard-icon svg path {
  fill: #FFFFFF;
}

.menu {
  padding-bottom: 35px;
  border-color: #FFFFFF;
  padding-left: 35px;
  padding-right: 35px;
}

.menu a span {
  /* white-space: nowrap; */
}

.notes .container {
  margin-bottom: 35px;
}

.notes .container p {
  font-size: 16px;
}

.notes a:link, .notes a:visited {
  color: #FFFFFF;
}

.notes a:hover, .notes a:active {
  color: #00c7cc;
}

.pagers {
  text-align: center;
  margin: 20px 0;
}

.message .subject {
  font-weight: bold;
}

.message .time {
  font-size: 12px;
  margin-bottom: 10px;
}

@media print {
  .btn {
    display: none;
  }
}

/* LESS Document */
/* PayQuad UI Kit Theme */
/* Colour Variables */
/* Global Styles */
/**for auto complete tags**/
h1 .icon-dashboard-tenants {
  color: #008b95 !important;
  font-size: 23px !important;
}

tags-input .tags .tag-item .remove-button {
  position: static;
}

.container-fluid.content.col-xs-10 {
  float: none;
}

.btn-clear {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  background: none;
}

/* END Global Styles */
/* UI Iconography */
.icon-sm {
  width: 28px;
  height: 28px;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
}

.icon-sm.accept svg circle {
  fill: #97d629;
}

.icon-sm:hover circle, .icon-sm.faded:hover circle, a:hover svg circle, a:hover svg {
  fill: #00c7cc;
}

.icomoon {
  font-size: 45px;
}

.panel-title .icon-sm {
  float: right;
  margin-left: 20px;
}

.annotation .icon-sm {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.annotation .icon-sm span {
  vertical-align: middle;
  display: inline-block;
  margin-top: -4px;
}

.annotation .icon-sm:hover circle {
  fill: #ea501b;
}

.annotation .icon-sm svg {
  max-width: 100%;
  height: inherit;
}

span.events {
  vertical-align: top;
  color: white;
  padding: 3px 7px;
  border-radius: 50%;
  background-color: #97d629;
}

.paid span.event {
  background-color: #97d629;
}

.unpaid span.event {
  background-color: #024953;
}

.declined span.event {
  background-color: #ea501b;
}

span.event {
  vertical-align: top;
  color: white;
  padding: 3px 7px;
  border-radius: 50%;
}

span.events-top {
  vertical-align: top;
  color: white;
  padding: 1px 8px;
  border-radius: 50%;
  background-color: #97d629;
  top: 2px;
  position: absolute;
  right: -10px;
  font-size: 12px;
}

#tab-content.tab-content.col-xs-11 {
  border-left: 2px solid #f2f2f2;
}

/* END UI Iconography */
a:link {
  text-decoration: none;
}

.table-hover > tbody > tr:hover > td, .table-hover > tbody > tr:hover > th {
  background-color: #C8C8C8;
}

.sections {
  margin-bottom: 20px;
  background-color: #f2f2f2;
  padding: 10px inherit;
}

.sections .section {
  display: inline-block;
  vertical-align: middle;
  padding: 5px 0;
  margin-right: 10px;
}

.sections span {
  color: #cccccc;
  vertical-align: middle;
}

.sections span.step {
  font-size: 12px;
  background-color: #cccccc;
  padding: 10px 20px;
  border-radius: 6px;
  color: #ffffff;
  display: none;
}

.sections .active span {
  color: #00c7cc;
}

.sections .active span.step {
  font-size: 12px;
  background-color: #00c7cc;
  padding: 10px 20px;
  border-radius: 6px;
  color: #ffffff;
  display: inline;
}

.steps span {
  color: #cccccc;
}

.steps span.active {
  color: #00c7cc;
  font-weight: bold;
}

.sections span, .steps span {
  margin-right: 10px;
  white-space: nowrap;
  font-size: 18px;
}

.inactive {
  color: #eeeeee;
}

.menu {
  text-align: center;
  border-bottom: none;
}

.menu .icon-l {
  font-size: 6em;
  padding: 10px 10px 0px;
}

.menu p {
  font-size: 1em;
  margin: 35px 0;
}

.notes {
  text-align: center;
  margin-top: 35px;
}

.notes .icon-s {
  font-size: 3em;
}

.buttons {
  text-align: center;
  margin-top: 35px;
}

.title {
  margin: 16px 0;
}

.description {
  padding: 5px 0;
}

.select-radio {
  margin-left: 100px;
}

.icon-xs {
  font-size: 1.5em;
  vertical-align: bottom;
}

.reset-password {
  margin-top: 100px;
}

.border-right {
  border-right: solid #000000 1px;
}

.border-left {
  border-left: solid #000000 1px;
}

.mail {
  padding: 0px;
}

.items-page {
  margin: 10px 0;
  text-align: right;
}

.pagination {
  margin: 0;
}

.filter {
  border-top: solid #000000 1px;
  border-bottom: solid #000000 1px;
}

.invites {
  margin-top: 35px;
}

.icon-md {
  font-size: 2em;
  padding: 0;
}

.colored.glyphicon-ok-circle {
  color: greenyellow;
}

.colored.glyphicon-time {
  color: orange;
}

.colored.glyphicon-circle-arrow-right {
  color: lightskyblue;
}

.colored.glyphicon-remove-circle {
  color: red;
}

span.colored.glyphicon-edit {
  border-radius: 50%;
  border: 1px solid;
  padding: 5px;
}

/* Layout Updates */
@font-face {
  font-family: 'Ubuntu-Regular';
  src: url("../../assets/ng1/fonts/ubuntu/Ubuntu-R.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu-Light';
  src: url("../../assets/ng1/fonts/ubuntu/Ubuntu-L.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu-Bold';
  src: url("../../assets/ng1/fonts/ubuntu/Ubuntu-B.ttf");
  font-weight: 900;
  font-style: normal;
}

html, body {
  height: 100%;
  font-family: 'Open Sans', sans-serif;
}

body {
  min-height: 100%;
}

/* Body */
.row.menu span.event-main {
  font-size: 0.3em;
  vertical-align: top;
  color: #222222;
  padding: 3px 7px;
  border-radius: 50%;
  background-color: #ADFF2F;
  position: absolute;
  right: 0;
  top: 0;
}

/** Media Screen*/
@media screen and (max-width: 768px) {
  #dashboard .main-container .container-fluid.content > .container {
    max-width: 100%;
  }
}

@media screen and (min-width: 769px) and (orientation: landscape) {
  #dashboard .main-container .container-fluid.content > .container {
    max-width: 960px;
  }
}

@media screen and (min-height: 576px) {
  #dashboard .main-container {
    height: 100%;
  }
  #dashboard .main-container .container-fluid.content {
    position: relative;
    top: 40%;
    transform: translateY(-40%);
    -webkit-transform: translateY(-40%);
  }
}

.sign-in, .sign-up {
  margin-bottom: 20px;
}

.sign-in ul, .sign-up ul {
  margin-bottom: 20px;
}

.sign-in .forgot-pass, .sign-up .forgot-pass {
  text-align: right;
  font-size: 12px;
}

.sign-in .remember-me, .sign-up .remember-me {
  font-size: 12px;
}

.sign-in h1, .sign-up h1 {
  padding: 20px 20px 20px 0;
  border-bottom: 1px solid #ffffff;
  margin-bottom: 20px;
}

.corp-brand {
  text-align: center;
  padding: 40px 0;
  border-bottom: 1px solid #f2f2f2;
  font-size: 32px;
}

.menu {
  padding-bottom: 35px;
  border-color: #ffffff;
  padding-left: 35px;
  padding-right: 35px;
}

.notes .container {
  margin-bottom: 35px;
}

.notes .container p {
  font-size: 16px;
}

.notes a:link, .notes a:visited {
  color: #ffffff;
}

.notes a:hover, .notes a:active {
  color: #00c7cc;
}

.pagers {
  text-align: center;
  margin: 20px 0;
}

.message .subject {
  font-weight: bold;
}

.message .subject:hover, .message .body:hover {
  text-decoration: underline;
  cursor: pointer;
}

.message .time {
  font-size: 12px;
  margin-bottom: 10px;
  text-align: right;
}

@media print {
  .btn, #app-footer {
    display: none;
  }
}

.main-container {
  min-height: 100%;
  padding-bottom: 100px;
}

#app-footer, .footer .payquad .info, .footer span, .footer .payquad .info a:link, .footer .payquad .info a:visited, .grey, .notes a:link, .notes a:visited {
  color: white !important;
  text-shadow: 1px 1px 1px black;
}

.dashboard-icon svg {
  -webkit-filter: drop-shadow(1px 1px 1px black);
  filter: drop-shadow(1px 1px 1px black);
}

.footer img, .dashboard-icon span, .menu svg, #notes-region span {
  -webkit-filter: drop-shadow(1px 1px 1px black);
  filter: drop-shadow(1px 1px 1px black);
}

_::-webkit-full-page-media, _:future, :root .safari_only {
  filter: url(#drop-shadow);
}

input.ng-invalid.ng-dirty {
  border: 1px solid #a80e0e;
}

.bg-danger {
  background-color: #f2dede;
  text-align: center;
  font-weight: bold;
  color: #5F3434;
}

.pagers .col-sm-4 {
  padding: 10px;
}

@media screen and (max-width: 768px) {
  #sidebar {
    top: 57px;
  }
}

#sidebar {
  display: none;
  position: fixed;
  top: 64px;
  width: 100%;
  height: calc(100vh - 51px);
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  overflow: hidden;
  z-index: 999;
}

#sidebar:before{
  content: "← Go back";
  position: absolute;
  left: 15px;
  top: 3px;
  text-decoration: underline;

  z-index: inherit;
  background: #bbbbbbc4;
  width: 90px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 5px;
}

#sidebar.open {
  display: block;
}

.sidebar-content {
  background: #fff;
  height: 100%;
  margin-left: 30%;
  padding: 30px 15px 15px;
  overflow-y: auto;
}

@media (max-width: 960px) {
  .sidebar-content {
    margin-left: 0;
  }
}

#sidebar.ng-enter, #sidebar.ng-leave,
#sidebar.ng-enter .sidebar-content, #sidebar.ng-leave .sidebar-content {
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

#sidebar.ng-enter {
  opacity: 0;
}

#sidebar.ng-enter .sidebar-content {
  transform: translateX(100%);
}

#sidebar.ng-enter-active {
  opacity: 1;
}

#sidebar.ng-enter-active .sidebar-content {
  transform: translateX(0%);
}

#sidebar.ng-leave {
  opacity: 1;
}

#sidebar.ng-leave .sidebar-content {
  transform: translateX(0%);
}

#sidebar.ng-leave-active {
  opacity: 0;
}

#sidebar.ng-leave-active .sidebar-content {
  transform: translateX(100%);
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

/*new designs*/
/*menu*/
.show-mobile {
  display: none;
}

.show-mobile-flex {
  display: none;
}

.show-mobile-override {
  display: none !important;
}

.nav-tenant .nav-more:link {
  opacity: 1 !important;
  filter: none !important;
}

.nav-tenant .nav-more .icon-sub {
  color: #000000;
}

.nav-tenant .nav-expand-mobile {
  position: fixed;
  width: 100vw;
  background-color: white;
  z-index: 101;
  height: calc(100% - 119px);
  overflow-y: auto;
}

.nav-tenant .nav-expand-mobile ul {
  list-style: none;
  padding: 1.5em;
}

.nav-tenant .nav-expand-mobile li {
  border-top: solid 1px #ddd;
  padding: 1.25em;
}

.nav-tenant .nav-expand-mobile li a {
  text-transform: uppercase;
  font-size: 12px;
  color: #333333;
  letter-spacing: 1px;
  font-weight: bold;
}

.nav-tenant .nav-expand-mobile .icon-sub {
  font-size: 12px !important;
  display: inline;
  padding-left: .25em;
}

.nav-tenant .icon-sub {
  font-size: 9px !important;
  text-transform: uppercase;
  font-family: Open Sans !important;
  padding: .75em 0 .25em;
  text-align: center;
  display: block;
  color: black;
}

.nav-tenant .icon-md li a {
  text-align: center;
}

@media only screen and (max-width: 887px) {
  .nav-tenant .navbar-header .info {
    float: right;
    padding: 13px 15px;
  }
  .nav-tenant .navbar-header .info img {
    width: 25px;
    opacity: 0.4;
    filter: grayscale(100%);
  }
  .nav-mobile {
    display: flex;
  }
  .nav-mobile li {
    margin: 0 auto;
  }
  .hide-mobile {
    display: none !important;
  }
  .show-mobile {
    display: block;
  }
  .show-mobile-flex {
    display: flex;
  }
  .show-mobile-override {
    display: block !important;
  }
  .nav-tenant .navbar-collapse {
    position: fixed;
    bottom: 0;
    z-index: 1000;
    background-color: #f8f8f8;
    width: 100%;
  }
  .nav-tenant.navbar .navbar-nav.icon-md li > a:link, .navbar .navbar-nav.icon-md li > a:visited {
    opacity: 0.6;
    filter: grayscale(100%);
  }
  .nav-mobile .active {
    filter: none !important;
    opacity: 1 !important;
    color: #008b95 !important;
  }
  .more-nav {
    filter: none !important;
    opacity: 1 !important;
    color: #008b95 !important;
  }
  .more-nav.collapsed {
    opacity: 0.6 !important;
    filter: grayscale(100%) !important;
  }
}

/*dashboard*/
.display-inline-flex {
  display: inline-flex;
}

#notes-region {
  margin-top: 0;
}

.new-header-dashboard-tenant {
  background-color: #f8f8f8 !important;
}

.main-container.active {
  background-color: #f6f6f6;
  /*background-image: url(../../assets/ng1/images/Rectangle-1.svg);
  background-position: 0 -400px;
  background-repeat: no-repeat;
  background-size: cover;*/
  background-image: none;
}

.dashboard-tenant .bg {
  position: absolute;
  left: 0;
  top: 62px;
  width: 100%;
  transition: all .36s ease 0s;
}

@media(max-width: 1457px) {
  .dashboard-tenant .bg {
    // top: 124px;
  }
}

.dashboard-tenant .bg svg {
  width: 100%;
}

.dashboard-tenant h3 {
  font-family: Open Sans;
  font-size: 10px;
  color: #000000;
  text-decoration: none solid black;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
}

.dashboard-tenant .upcoming-title {
  padding-top: 10px;
  margin-bottom: 5px;
}

.dashboard-tenant .greeting-message {
  font-family: Open Sans;
  font-size: 24px;
  color: #ffffff;
  text-decoration: none solid white;
  text-align: center;
  padding: 1.5em;
  z-index: 1;
  position: relative;
}

.dashboard-tenant .notification-message {
  text-align: center;
  padding: 1em;
  color: black;
  border-radius: 20px;
  background: white;
  box-shadow: 0px 10px 40px rgba(250, 182, 182, 0.25);
}

.dashboard-tenant .notification-message .message {
  font-family: Open Sans;
  font-size: 14px;
  color: #000000;
  text-decoration: none solid black;
  line-height: 18px;
  text-align: center;
}

.dashboard-tenant .container-action {
  margin-bottom: 1em;
  text-align: center;
  padding: 1em;
  color: black;
  border-radius: 20px;
  background: white;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.25);
  position: relative;
  padding-bottom: 60px;
  min-height: 221px;
}

.dashboard-tenant .container-action .action-button {
  background-color: rgba(0, 141, 151, 0.3);
  border-top: #008d97 solid 2px;
  color: black !important;
  text-shadow: none;
  position: absolute;
  bottom: 0;
  padding: 1em;
  width: 100%;
  display: block;
  left: 0;
  border-radius: 0 0 20px 20px;
  font-family: Open Sans;
  font-size: 14px;
  color: #000000;
  text-decoration: none solid black;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
}

.dashboard-tenant .container-action .fee-balance {
  font-family: Open Sans;
  font-size: 20px;
  color: #222222;
  text-decoration: none solid #222222;
  line-height: 26px;
  text-align: center;
  text-shadow: none;
}

.dashboard-tenant .container-action .fee-balance .fee-balance-sub {
  display: block;
  font-family: Open Sans;
  font-size: 10px;
  color: #000000;
  text-decoration: none solid black;
  line-height: 16px;
  letter-spacing: 1px;
  text-align: center;
  text-shadow: none;
  filter: none !important;
}

.dashboard-tenant .container-action .fee-info {
  font-family: Open Sans;
  font-size: 14px;
  color: #222222;
  text-decoration: none solid #222222;
  line-height: 18px;
  text-align: center;
  font-weight: bold;
  text-shadow: none;
  margin-bottom: 5px;
}

.dashboard-tenant .container-action .fee-date {
  font-family: Open Sans;
  font-size: 14px;
  color: #222222;
  text-decoration: none solid #222222;
  line-height: 18px;
  text-align: center;
}

.show-more {
  filter: none !important;
  font-weight: bold;
  text-decoration: underline;
}

.notes a.make-payment {
  text-shadow: none !important;
  color: black !important;
  text-decoration: underline;
  font-weight: bold;
}

@media (max-width: 887px) {
  .main-container.active {
    /*background-position: 0 -500px;*/
  }
  .dashboard-tenant .bg {
    top: 51px;
  }
  footer {
    display: none;
  }
}

.button-teal-border {
  background-color: rgb(0, 141, 151);
  background-color: rgba(0, 141, 151, 7%);
  font-family: Open Sans;
  font-size: 14px;
  color: #000000;
  font-weight: bold;
  text-decoration: none solid rgb(0, 0, 0);
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  border: 1px solid #008d97;
}

.tab-request-status {
  background-color: #f6f6f6;
  padding: 1em !important;
  color: #444444;
  text-transform: capitalize;
}

.export-button {
  font-size: 12px;
}

.export-button img {
  width: 12px;
}

.margin-left {
  margin-left: .5em;
}

.margin-right {
  margin-right: .5em;
}

.margin-left-none {
  margin-left: 0;
}

.entry {
  max-width: 100%;
  border: solid 1px #ddd;
  border-radius: 20px;
  padding: 0 !important;
  color: #000000;
  margin-bottom: 10px;
}

.entry .col-12 {
  padding: 10px !important;
}

.entry .border-bottom {
  border-bottom: solid 1px #ddd;
}

.entry .top-row {
  font-weight: bold;
  border-radius: 15px 15px 0 0;
}

.status-icon-medium {
  color: #ffc30f;
}

.status-icon-emergency {
  color: #e81c1c;
}

.status-icon-low {
  color: #b5b5b5;
}

.entry .status-icon {
  font-size: 8px;
  margin-right: .5em;
  vertical-align: middle;
}

.entry .description {
  color: #444444;
}

.message-count {
  background-color: #97d629;
  border-radius: 5px;
  padding: 0 10px;
  margin-right: 5px;
}

.float-right {
  float: right;
}

.active-tab {
  background-color: rgb(0, 141, 151);
  background-color: rgba(0, 141, 151, 7%);
  border-bottom: solid 5px #008d97;
}

ul.pagination {
  float: right;
}

@media (max-width: 768px) {
  .title-main h1 {
    float: none !important;
  }
  .title-main {
    text-align: center;
  }
  .status-history-cell {
    width: auto !important;
    padding: 10px 0 !important;
  }
  .tabs-style-fillup nav ul li a {
    font-size: 12px;
  }
  .form-pass .table tbody tr td {
    padding: 8px 0;
  }
  #panel5 .table tbody tr th, #panel5 .table tbody tr td {
    white-space: initial;
  }
  .table .col-icon {
    padding-left: 5px;
  }
  #bulk-message-textarea .btn-toolbar, #message-textarea .btn-toolbar {
    display: none;
  }
  #bulk-message-textarea .btn-toolbar .btn-group, #message-textarea .btn-toolbar .btn-group {
    display: none;
  }
  #toolbarWC {
    display: none !important;
  }
  .btn {
    padding: 5px 10px;
  }

  .nav-tabs li a{
    padding: 10px 5px;
    font-size: 12px;
  }

  .filtered-table{
    margin-left: 0;
  }
}

/* Absolute Center Spinner */
.loading-page-spinner {
  position: fixed;
  z-index: 9999;
  height: 2em;
  width: 100%;
  text-align: center;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  font-size: 60px;
}

/* Transparent Overlay */
.loading-page-spinner:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

@media print {
  .loading-page-spinner{
    display: none;
  }
}

.has-error {
  .ms-choice {
    border-color: #a94442 !important;
  }
}

.large-inputs-group {
  .ms-parent {
    max-width: 100% !important;
    padding: 0;
    border: white;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .ms-choice {
    height: 42px;

    & > span {
      //top: 3px;
    }
  }
}


.mr-managers {
  .ms-choice {
    span {
      top: -4px;
    }
  }
}

.form div.properties-wrap {
  padding-right: 0;
}

@media (max-width: 992px) {
  .form div.properties-wrap {
    padding: 5px 0;
  }
}

.header-payments-table tr th a#charts-toggle-btn {
  font-size: 1.4em;
  font-weight: normal;
}

.calendar-btn {
  padding: 3px 0;
  position: relative;

  input {
    margin: 0 auto;
    position: absolute;
    top: 50%;
    right: 5px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.reportIcon {
  border: 1px solid transparent;
  padding: 2px;
}

.activeReportIcon {
  box-shadow: 0 0 5px #024953;
  border: 1px solid #024953;
  padding: 2px;
}

.mr-toggle {
  display: inline-block;

  a, i {
    display: inline-block;
    margin-right: 10px;
    font-weight: bold;
  }

  i {
    font-size: 33px;
    position: relative;
    top: 8px;

    &:hover {
      color: #024953;
    }
  }
}

.ms-drop{
  li{
    span{
      margin-left: 4px;
    }
  }
}

@media(max-width: 768px) {
  .pq-mobile-popup{
    height: 100%;
    margin: 0;

    .modal-content, .modal-body{
      height: 100%;
      border-radius: 0;
      border: none;
    }
  }
}

.ta-variables-list{
  position: absolute !important;
  z-index: 2;
  list-style: none;
  background: #fff;
  border: 1px solid #000;
  right: 0;
  padding: 0;
  top: 41px;

  li{
    width: 111px;
    padding: 10px;
    border-bottom: 1px solid #000;
    cursor: pointer;

    &:last-child{
      border-bottom: 0;
    }
  }
}

pq-back-button .pq-back-button {
  display: none;
}

#navbar-collapse-2 .dropdown.open .dropdown-backdrop {
  position: absolute;
}

@media (max-width: 960px) {
  .sidebar-content.ng-scope {
    padding-bottom: 110px;
  }
}

.modal.fade.privacy .modal-content {
  height: calc(100vh - 50px);
  overflow-y: auto;
}

.maintenance-tasks-email-row {
  display: flex;
  align-items: center;
  flex-flow: row-reverse;
}

.navbar-collapse.mobile-or-tablet {
  position: absolute;
  z-index: 1000;
  background-color: #f8f8f8;
  width: 100%;

  .hide-mobile {
    display: none !important;
  }

  .show-mobile-override {
    display: block !important;
  }
}

#app-footer.mobile-or-tablet {
  display: none;
}

@media screen and (max-width: 991px) {
  .calendar-btn {
    font-size: 10px;
    display: block;
    width: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 3px 15px 3px 3px;
  }
}

footer#app-footer {
  position: static;
  width: 100%;
}

.footer_padding.mobile-or-tablet {
  display: none;
}

.padding_to_stay_visible {
  padding-bottom: 200px;
}

@media screen and (max-width: 888px) {
  .footer_padding {
    display: none;
  }

  .padding_to_stay_visible {
    padding-bottom: 100px;
  }
}

#newng_content {
  padding-bottom: 50px;
  width: 100%;
}

div#app-wrapper {
  display: flex;
  flex-direction: column;
}

div#main-region {
  flex: 1;
  width: 100%;
}


@media screen and (max-width: 660px) {
  div#app-wrapper {
    display: block;
  }

  .fc button {
    font-size: 0.8em;
  }
}

.container-fluid.mobile-or-tablet .navbar-header button.navbar-toggle {
  display: none;
}

.mobile-or-tablet ul.nav.navbar-nav.icon-md.nav-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#datetimepicker1 .ui-datepicker-trigger, #datetimepicker2 .ui-datepicker-trigger, #datetimepicker3 .ui-datepicker-trigger {
  z-index: 99;
}

button.navbar-toggle.h-mobile.mobile-or-tablet {
  display: none;
}

button.navbar-toggle.mobile-or-tablet {
  display: none;
}

.checkbox.row_with_timezone {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  margin: 20px;

  label {
    margin-right: 20px;
  }
}

.filter_icon {
  text-align: right;

  img {
    width: 25px;
  }
}

.filter_buttons {
  display: none;

  .managerMobileFilterButton {
    width: 100%;
  }
}

.filter_buttons.active {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 660px) {
  .filter_buttons .managerMobileFilterButton button{
      font-size: 13px;
    }
}

.filter_icon {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

@media screen and (min-width: 600px) {
  .loginBox.loginBoxMobile {
    width: 80%;
  }
}

.row.login_row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.manager-navbar-label.mobile-menu-item {
  display: inline;
}

@media screen and (max-width: 1200px) {
  a.dashboard-icon span {
    font-size: 14px;
  }
}

  #sidebar.open .sidebar-content>.row {
    margin-bottom: 100px;
  }


.nav-tenant .navbar-collapse.mobile-or-tablet {
  position: fixed;
  bottom: 0;
}

.navbar-collapse.mobile-or-tablet .hide-mobile.show-for-realview {
  display: block !important;
}

@media screen and (min-width: 768px) {
  .navbar-collapse.dashboard-navbar.mobile-or-tablet {
    position: static;
    z-index: 1000;
    background-color: transparent;
    width: 100%;
  }
}

.list-card {
  border-left-style: solid;
  border-left-width: 7px;
}

.list-card.status-open {
  border-left-color: grey;
}
.list-card.status-review {
  border-left-color: #868637;
}
.list-card.status-approved {
  border-left-color: green;
}
.list-card.status-rejected {
  border-left-color: #9e6868;
}
.list-card.status-accepted {
  border-left-color: blueviolet;
}
.list-card.status-signed {
  border-left-color: wheat;
}
.list-card.status-complete {
  border-left-color: orange;
}
.list-card.status-converted {
  border-left-color: black;
}

span.label.status-open.badge_status {
  background-color: grey;
}

span.label.status-review.badge_status {
  background-color: #868637;
}

span.label.status-approved.badge_status {
  background-color: green;
}

span.label.status-rejected.badge_status {
  background-color: #9e6868;
}

span.label.status-accepted.badge_status {
  background-color: blueviolet;
}

span.label.status-signed.badge_status {
  background-color: wheat;
}

span.label.status-complete.badge_status {
  background-color: orange;
}

span.label.status-converted.badge_status {
  background-color: black;
}

span.label.status-Booked.badge_status {
  background-color: orange;
}

span.label.status-Completed.badge_status {
  background-color: green;
}

span.label.status-No-show.badge_status {
  background-color: blueviolet;
}

span.label.status-Cancelled.badge_status {
  background-color: red;
}
span.label.status-Canceled.badge_status {
  background-color: red;
}


.list-card.status-Booked {
  border-left-color: orange;
}

.list-card.status-Completed {
  border-left-color: green;
}

.list-card.status-No-show {
  border-left-color: blueviolet;
}

.list-card.status-Cancelled {
  border-left-color: red;
}
.list-card.status-Canceled {
  border-left-color: red;
}


span.label.status-In-Progress.badge_status {
  background-color: orange;
}

span.label.status-Completed.badge_status {
  background-color: green;
}

span.label.status-Scheduled.badge_status {
  background-color: blueviolet;
}

span.label.status-Cancelled.badge_status {
  background-color: red;
}
span.label.status-Canceled.badge_status {
  background-color: red;
}


.list-card.status-In-Progress {
  border-left-color: orange;
}

.list-card.status-Completed {
  border-left-color: green;
}

.list-card.status-Scheduled {
  border-left-color: blueviolet;
}

.list-card.status-Cancelled {
  border-left-color: red;
}
.list-card.status-Canceled {
  border-left-color: red;
}

span.label.status-Paid.badge_status {
  background-color: #97d629;
}

span.label.status-Pending.badge_status {
  background-color: #ffa500;
}

span.label.status-Declined.badge_status {
  background-color: #ea501b;
}

span.label.status-Deleted.badge_status {
  background-color: #ea1b1b;
}


.list-card.status-Paid {
  border-left-color: #97d629;
}

.list-card.status-Pending {
  border-left-color: #ffa500;
}

.list-card.status-Declined {
  border-left-color: #ea501b;
}

.list-card.status-Deleted {
  border-left-color: #ea1b1b;
}

@media screen and (max-width: 768px) {
  #sum_box h4 {
    font-size: 18px !important;
  }

  #sum_box .description {
    padding-top: 0 !important;
    font-size: 13px !important;
  }
}


@media screen and (max-width: 991px) {
  div#sum_box>div {
    float: none;
    margin-top: 5px;
    padding: 0 5px;
  }

  div#sum_box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
}

.panel_info {
  padding-right: 10px;
}

#sum_box .panel-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 568px) {
  #sum_box .icon {
    font-size: 25px !important;
  }

  #sum_box .description {
    width: 45px;
  }
}

@media screen and (min-width: 991px) {
  .panel_info .description {
    font-size: 13px !important;
  }
}

.panel-body {
  padding: 15px 5px;
}

#sum_box .panel-body:before, #sum_box .panel-body:after {
  display: none;
}

span.label.status-in-progress.badge_status {
  background-color: orange;
}

span.label.status-open.badge_status {
  background-color: grey;
}

span.label.status-completed.badge_status {
  background-color: green;
}

span.label.status-cancelled.badge_status {
  background-color: red;
}
span.label.status-canceled.badge_status {
  background-color: red;
}

.list-card.status-in-progress {
  border-left-color: orange;
}

.list-card.status-open {
  border-left-color: grey;
}

.list-card.status-completed {
  border-left-color: green;
}

.list-card.status-cancelled {
  border-left-color: red;
}
.list-card.status-canceled {
  border-left-color: red;
}

.list-card.status-rejected {
  border-left-color: #9e6868;
}

.mr_status span.label.status-open.badge_status {
  background-color: green;
}

.mr_status.list-card.status-open {
  border-left-color: green;
}

.mr_status span.label.status-completed.badge_status {
  background-color: #024953;
}

.mr_status.list-card.status-completed {
  border-left-color: #024953;
}

@media (max-width: 568px) {
  .managerMobileFilterButton .btn {
    padding: 5px;
    font-size: 10px !important;
  }
}

@media (max-width: 320px) {
  .managerMobileFilterButton .btn {
    padding: 5px 2px;
    font-size: 9px !important;
  }
}

.cordova_select .ms-drop input[type="radio"], .cordova_select .ms-drop input[type="checkbox"] {
  margin-left: -2.25rem !important;
}

.cordova_select .ms-drop ul>li {
  padding: 0 8px !important;
}

.dashboard-tenant .bg.mobile-or-tablet {
  top: 51px !important;
}

.dashboard-tenant .bg.cordova-device {
  top: -20px !important;
}

.clickable {
  cursor: pointer;
}

@media (max-width: 768px) {
  .inspection__condition {
    padding-right: 0px;
    padding-left: 5px;
  }
}

ul.nav.nav-tabs {
  margin-top: 10px;
}

#scheduled_dropdown {
  display: none;
}

#scheduled_dropdown.active {
  display: block;
}

.hidden {
  display: none;
}

// .daterangepicker {
//   top: calc(100% - 300px); __<<ngThemingMigrationEscapedComment116>>__
// }



.edit-inline .template_select {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #f2f2f2;
  background-image: none;
  border: 1px solid #024953;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.prospect .btn-group {
  display: none;
}

.prospect .btn-group:nth-child(2) {
  display: block !important;
}

.ta-editor.ta-html, .ta-scroll-window.form-control {
  height: 150px;
  min-height: 100px;
}

.list-group-item {
  position: static;
}

div#message-textarea.prospect {
  height: 200px;
}

div#sum_box > div:first-child {
  padding-left: 0;
}

div#sum_box > div:last-child {
  padding-right: 0;
}

div#sum_box {
  padding: 5px 0;
}

@media (max-width: 991px) {
  div#sum_box > div:first-child {
    padding-left: 5px;
  }

  div#sum_box > div:last-child {
    padding-right: 5px;
  }
}

form#signin-form .form-item {
  padding-left: 15px;
}

.row.display-flex .form-item {
  padding-left: 15px;
}

@import "navbar-refactor";

.drp-buttons {
  text-align: center !important;
  .applyBtn, .cancelBtn {
    min-width: 100px !important;
    margin-left: unset;
  }
}

.dialog-responsive {
  width: 600px;
  margin: 50px 20px 80px;
}

@media only screen and (max-width: 760px) {
  .dialog-responsive {
      width: 90%;
  }
}

.title-container {
  @include h5-bold;
}

.message-page-list {
  &.mat-mdc-menu-panel {
    border-radius: 24px;
    min-width: 200px;
  }

  .mat-mdc-menu-content {
    grid-area: folder-list;
    height: 100%;
    padding-right: 0;
    border-top: 1px solid var($--gray-8);
    border-right: 1px solid var($--gray-8);
    box-sizing: border-box;

    .folder {
      @include h9;
      @include accent-font-3;
      cursor: pointer;
      line-height: 22px;
      display: flex;
      justify-content: space-between;
      padding: 16px 16px 16px 30px;
      background: linear-gradient(to left, transparent 50%, var($--primary-6) 50%) right;
      background-size: 200%;
      transition: background-position 0.2s ease;
      &.active {
        background-position: left;
        .folder-name {
          color: var($--primary-1);
        }
      }
      .pq-badge-flat {
        border-radius: 29px;
        font-weight: 600;
      }
      .folder-name {
        display: flex;
      }
      .mat-icon {
        width: 22px;
        height: 22px;
        margin-right: 14px;
      }
    }
  }
}

.mat-mdc-dialog-surface {
  min-width: 0 !important;
}

.user_agree_checkbox_info {
  font-weight: bold !important;
  text-decoration: underline !important;
}

.underline-text {
  text-decoration: underline;
}

.table tbody tr td .referral_sources {
  display: flex; 
  flex-wrap: wrap; 
  gap: 15px;
  padding:10px;

  .referral_chip {
    display: flex; 
    align-items: center; 
    gap: 5px; 
    background-color: #f0f0f0; 
    padding: 5px 10px; 
    border-radius: 20px;

    .remove_referral {
      color: red; 
      cursor: pointer; 
      font-weight: bold; 
      font-size: 18px;
    }
  }
}