@import "src/styles/mixin";

input.mat-mdc-input-element {
  @include forms-input;
}

label {
  @include forms-label;
  color: var($--font-2);
}

.mat-mdc-form-field {
  max-width: 100%;
}

.mat-mdc-form-field-error {
  @include body4;
  a {
    @include accent-primary-1;
  }
}
mat-error.mat-mdc-form-field-error {
  color: #f44336;
  display: flex;
  flex-direction: column;
}

mat-form-field .mdc-text-field--outlined {
  --mat-form-field-container-height: 52px;
  --mdc-outlined-text-field-container-shape: 8px;
  --mdc-outlined-text-field-outline-color: var(--gray-7);
  background-color: white;
}

.mdc-text-field--outlined:has(mat-select) {
  height: var(--mat-form-field-container-height) !important; // dropdown wasn't getting --mat-form-field-container-height
}

.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;

  .mat-mdc-form-field-bottom-align::before {
    height: 4px; // mat-error is being pushed down
  }
}

.mat-mdc-form-field-subscript-wrapper:has(mat-error) {
  margin-bottom: 1.34375em;
}
