@import "src/styles/mixin";

.quill__ngx-file-drop__drop-zone {
  margin: 0;
  border-radius: 10px;
  border: 5px dotted transparent;
  box-sizing: border-box;
  transition: border 0.1s;
  position: relative;
  .quill__ngx-file-drop__drop-zone--over__label {
    display: none;
    pointer-events: none;
  }
  &.ngx-file-drop__drop-zone--over {
    background-color: var($--background-4)!important;
    background-clip: padding-box;
    border: 5px dotted rgba(0, 0, 0, 0.1);
    .quill__ngx-file-drop__content {
      quill-editor {
        opacity: 0;
      }
    }
    .quill__ngx-file-drop__drop-zone--over__label {
      display: block;
      position: absolute;
      background-color: var(--background-4);
      width: 100%;
      height: 100%;
      text-align: center;
      padding-top: 20%;
      box-sizing: border-box;
      @include h6;
      @include accent-font-4;
    }
  }
}

.quill__ngx-file-drop__content {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  transition: opacity 0.1s;
  box-sizing: border-box;
}

.quill__ngx-file-drop__drop-zone-label {
  text-align: center;
}

.quill__ngx-file-drop__file-input {
  display: none;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  @include accent-primary-1;
}