.cordova ul.navbar-nav a .fa { font-size: 30px !important;}

.cordova ul.navbar-nav.icon-md {
    display: flex;
    align-items: stretch; /* Default */
    justify-content: space-between;
    width: 100%;
    margin: 0;
}

.cordova ul.navbar-nav.icon-md li {
    display: block;
    flex: 0 1 auto; /* Default */
    list-style-type: none;
}

.cordova .cordovaHide, .cordova .cordovaHide .fa.fa-print{ display: none !important;}

.cordova #datetimepicker1 .ui-datepicker-trigger,
.cordova #datetimepicker2 .ui-datepicker-trigger{
    min-height: 100%;
}

.cordova .icon-cancel.danger {
    font-size: 32px;
}


.cordova pq-pagination .icon-icon-chevron-right,
.cordova pq-pagination .icon-icon-chevron-left{
    zoom: 200%;
    margin: 0;
}

.cordova pq-pagination ul {
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

.cordova td,
.cordova th{

    white-space: normal !important;
}


.cordova dir-pagination-controls .icon-icon-chevron-right,
.cordova dir-pagination-controls .icon-icon-chevron-left{
    zoom:175%;
    margin:0;
}

.cordova dir-pagination-controls ul { padding-top:5px;}

.cordova dir-pagination-controls li a{
width: 28px !important;
height: 28px !important;
}

.cordova .ui-widget-content {
    background-image: url("../bower_components/jqueryui/themes/ui-lightness/images/ui-bg_highlight-soft_100_eeeeee_1x100.png");
}
.cordova .ui-widget-header .ui-icon{
    background-image:url("../bower_components/jqueryui/themes/ui-lightness/images/ui-icons_ffffff_256x240.png");
}

.cordova .ui-widget-header {
    background-image: url("../bower_components/jqueryui/themes/ui-lightness/images/ui-bg_gloss-wave_35_f6a828_500x100.png");
}

.cordova .ui-widget-header .ui-state-default {
    background-image: url("../bower_components/jqueryui/themes/ui-lightness/images/ui-bg_glass_100_f6f6f6_1x400.png");
}

.cordova .ui-widget-header .ui-state-highlight {
    background-image: url("../bower_components/jqueryui/themes/ui-lightness/images/ui-bg_highlight-soft_75_ffe45c_1x100.png");
}

.ios {
    padding-top: 20px;
    padding-top: env(safe-area-inset-top);
    padding-top: constant(safe-area-inset-top);
}
.toolbar-container {
  top: 20px !important;
  top: env(safe-area-inset-top) !important;
  top: constant(safe-area-inset-top) !important;
}

.mat-drawer-inner-container {
  margin-top: 20px !important;
  margin-top: env(safe-area-inset-top) !important;
  margin-top: constant(safe-area-inset-top) !important;
}

.dashboard-container {
  margin-top: 20px !important;
  margin-top: env(safe-area-inset-top) !important;
  margin-top: constant(safe-area-inset-top) !important;
}
#dynamic-island-cover-ios {
  display: block !important;
}
.ng-toolbar-container {
  top: 20px !important;
  top: env(safe-area-inset-top) !important;
  top: constant(safe-area-inset-top) !important;
}
.ios .navbar .nav-mobile {
    padding-bottom: 20px;
    padding-bottom: env(safe-area-inset-bottom);
    padding-bottom: constant(safe-area-inset-bottom);
}

.ios .not-tenant.non-active {
    overflow: auto;
    max-height: 100vh;
}

.ios #main-region {
    padding-bottom: calc(env(safe-area-inset-bottom) * 1.5);
}



.cordova pq-back-button .pq-back-button {
    /* display: none; */
    display: inline-block;
    margin-bottom: 10px;
    font-size: 16px;
    min-width: unset;
    padding-left:0;
}

.cordova pq-back-button .pq-back-button i {
    margin-right: 10px;
}

.ios .main-container, .ios .app-con {
    max-height: 100vh;
    overflow: auto;
}

.ios .modal {
    top: 20px;
    top: env(safe-area-inset-top);
    top: constant(safe-area-inset-top);

    bottom: 20px;
    bottom: env(safe-area-inset-bottom);
    bottom: constant(safe-area-inset-bottom);
}

.ios .nav-expand-mobile {
    top: 20px;
    top: env(safe-area-inset-top);
    top: constant(safe-area-inset-top);

    padding-top: 20px;
    padding-top: env(safe-area-inset-top);
    padding-top: constant(safe-area-inset-top);
}

.ios #toast-container {
    top: 20px;
    top: env(safe-area-inset-top);
    top: constant(safe-area-inset-top);
}

.ios .pq-back-button {
    display: inline-block;
}

.ios .mobile-checkbox-text-spacing {
    margin-left: 10px;
}

.ios .mobile-checkbox-text-spacing-lg {
    margin-left: 25px;
}

.ios .dashboard-tenant .bg {
    top: -20px;
}

.ios [ui-view=body].container-fluid, .ios .app-con {
    position: relative;
}

.cordova .nav-tenant .nav-expand-mobile {
    height: calc(100vh - 62px)!important;
    top: 0!important;
}

.cordova .plaid-link-iframe {
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
}

.cordova .ms-drop input[type=checkbox] {
    position: relative;
}

.android .companyicon {
    padding-top: 28px;
}

@media (min-width: 768px) {
    .ios {
        -webkit-overflow-scrolling: touch;
    }

    .ios * {
        /* -webkit-overflow-scrolling: touch; */
    }

    .ios #app-wrapper {
        -webkit-overflow-scrolling: touch;
    }

    .ios .not-tenant.non-active {
        max-height: unset;
        -webkit-overflow-scrolling: touch;

    }

    .ios .main-container {
        max-height: unset;
        -webkit-overflow-scrolling: touch;
    }
}

@media (max-width: 767px) {
    .android .dashboard-tenant .bg.cordova-device {
        top: 51px!important;
    }
}
